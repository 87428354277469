import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import routeUtils from 'js/routerUtils';
import TopbarWight from 'h5/compontnts/topbar/topbar';
import BannerTextHome from 'assets/h5/banner_text_home.svg';
import { Button } from 'antd';
import btnEnterSvg from 'assets/h5/btn_enter.svg';

import videoBtn1On from 'assets/svg/video_btn1_on.svg';
import videoBtn2On from 'assets/svg/video_btn2_on.svg';
import videoBtn3On from 'assets/svg/video_btn3_on.svg';
import BottomCallBar from 'h5/compontnts/bottomCallBar/bottomCallBar';
import PartnersWight from 'h5/compontnts/partners';
import { Swiper } from 'antd-mobile';
import React from 'react';
import CustomerMobWidget from 'h5/compontnts/commonCustomer';
import BottomBarMobWidget from 'h5/compontnts/bottombar/bottombar';
import QuickNumber from 'pages/home/components/quickNumber';

class HomeWidgetMobile extends BaseMobileComponents {
    constructor() {
        super();
        this.state = {
            countinglist: [{
                id: 0,
                countNum: 10,
                countUnit: '万+',
                desc: '服务商家数',
                icon: require('../../assets/counting_icon_1.png')
            }, {
                id: 1,
                countNum: 9,
                countUnit: '分钟',
                desc: '平均到店',
                icon: require('../../assets/counting_icon_2.png')
            }, {
                id: 2,
                countNum: 28,
                countUnit: '分钟',
                desc: '平均送达',
                icon: require('../../assets/counting_icon_3.png')
            }, {
                id: 3,
                countNum: 400,
                countUnit: '座+',
                desc: '开通城市',
                icon: require('../../assets/counting_icon_4.png')
            }, {
                id: 4,
                countNum: 100,
                countUnit: '万+',
                desc: '日处理订单',
                icon: require('../../assets/counting_icon_5.png')
            }],
            stepList: [{
                title: '1.下载注册',
                desc: '应用市场下载大有单并注册',
                iconUnfocus: videoBtn1On,
            }, {
                title: '2.绑定渠道',
                desc: '绑定美饿等线上渠道同步订单',
                iconUnfocus: videoBtn2On,
            }, {
                title: '3.呼叫配送',
                desc: '自动开通全网运力，全网比价呼单，自动回传',
                iconUnfocus: videoBtn3On,
            }],
            applist: [{
                title: 'iOS',
                url: '',
                img: '',
                imgFocus: '',
                svg: <svg t="1684751371492" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1946" width="128" height="128"><path d="M724.384 428.64l82.784-117.856c4.896-7.008 3.68-16.192-2.752-20.544-6.4-4.352-15.616-2.176-20.512 4.8l-85.984 122.464A515.712 515.712 0 0 0 512 383.36c-66.304 0-129.28 12.224-185.92 34.112L240.096 295.04c-4.896-7.04-14.08-9.184-20.512-4.832-6.4 4.352-7.648 13.536-2.72 20.544l82.752 117.856C168.032 488.704 75.744 602.816 64 736h896c-11.744-133.184-104.032-247.296-235.616-307.36zM323.008 614.4c-27.392 0-49.6-21.76-49.6-48.64 0-26.848 22.208-48.64 49.6-48.64 27.36 0 49.568 21.792 49.568 48.64 0 26.88-22.208 48.64-49.6 48.64z m384.192 0c-27.36 0-49.568-21.76-49.568-48.64 0-26.848 22.176-48.64 49.6-48.64 27.36 0 49.536 21.792 49.536 48.64 0 26.88-22.176 48.64-49.568 48.64z" fill="#272636" p-id="1947"></path></svg>
            }, {
                title: 'iOS',
                url: '',
            }],
            swiperList1: [
                {
                    icon: require('assets/h5/home_banner_pic_1.png')
                },
                {
                    icon: require('assets/h5/home_banner_pic_2.png')
                },
                {
                    icon: require('assets/h5/home_banner_pic_3.png')
                },
                {
                    icon: require('assets/h5/home_banner_pic_4.png')
                },
                {
                    icon: require('assets/h5/home_banner_pic_5.png')
                }
            ]
        };
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === "micromessenger";
    }

    toDownload() {
        routeUtils.routeTo('/app');
    }

    toProduct() {
        routeUtils.routeTo('/h5/product');
    }

    renderView() {
        const { stepList, countinglist, swiperList1 } = this.state;
        return (
            <div className="root-view-mob bg-white">
                <div className="banner-view">
                    <TopbarWight></TopbarWight>
                    <img src={BannerTextHome} className='banner-text' alt='大有单'></img>
                    <div className='sub-text'>专注于全渠道聚合订单管理与全链路配送服务</div>
                    <div>
                        <Button type='primary' style={{ marginTop: 12 }}>
                            <div className='flex-align-center' onClick={this.toDownload.bind(this)}>免费注册
                                <img src={btnEnterSvg} className='margin-left-xs'></img></div>
                        </Button>
                    </div>
                </div>
                <div className='second-part'>
                    <div className='counting-bar'>
                        <div className='cu-list grid col-5 margin-lr-sm' style={{ height: '100%' }}>
                            {
                                countinglist.map((item, index) => {
                                    return (
                                        <div key={index} className='inner-item flex-on-center flex-direction'>
                                            <img src={item.icon} className='inner-icon' alt={item.desc}></img>
                                            <div className='inner-count'>
                                                <span className='inner-count-num'>
                                                    <QuickNumber maxNum={item.countNum} key={index} index={index}></QuickNumber>
                                                </span>
                                                <span className='inner-count-unit'>{item.countUnit}</span>
                                            </div>
                                            <div className='inner-count-desc'>{item.desc}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='common-inner-text-1 inner-text-1'>大有单解决方案</div>
                    <div className='common-inner-text-2'>SAAS平台，赋能本地生活服务商家</div>
                    <img src={require('assets/h5/home_img_p1.png')} className='inner-image'></img>
                    <Button type='primary' className='margin-top' onClick={this.toProduct.bind(this)}>了解详情</Button>
                </div>
                <div className='third-part'>
                    <div className='common-inner-text-1 inner-text-1'>各行各业，都能适用大有单</div>
                    <div className='common-inner-text-2'>适配餐饮、鲜花蛋糕、果蔬生鲜、商超、医药等行业</div>
                    <div className='inner-banner-view'>
                        <Swiper slideSize={80} trackOffset={15} stuckAtBoundary={false} defaultIndex={0} autoplay={true} style={{
                            '--track-padding': ' 0 0 16px',
                            '--active-dot-color': 'white',
                            '--dot-spacing': '8px',
                        }} loop={true}>
                            {
                                swiperList1.map((item, index) => {
                                    return (
                                        <Swiper.Item key={index}>
                                            <div className='padding flex-on-center'>
                                                <img src={item.icon} style={{ width: 280, height: 152 }} alt='行业'></img>
                                            </div>
                                        </Swiper.Item>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
                <div className='fouth-part'>
                    <div>
                        <span className='inner-text-num main-color'>3</span>
                        <span className='inner-text'>步拥有大有单</span>
                    </div>
                    <div className='common-inner-text-2'>百余项功能免费开放中，简单3步，轻松拥有</div>
                    <div className='padding-lr' style={{ marginTop: 32 }}>
                        {
                            stepList.map(
                                (item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={`step-item step-item-on`}>
                                                <img src={item.iconUnfocus} className="icon-step-round" alt="步骤"></img>
                                                <div className="text-left step-right">
                                                    <div className="step-title">{item.title}</div>
                                                    <div className="step-desc">{item.desc}</div>
                                                </div>
                                                <div className={`step-number f0`}>0{index + 1}</div>
                                            </div>
                                            <div className='flex'>
                                                {
                                                    index !== stepList.length - 1 ? (<img src={require('assets/h5/double_down.png')} className='double-down' alt='步骤'></img>) : ''
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
                <div className='fifth-part padding-lr-xs'>
                    <div className='common-inner-text-1 inner-text-1'>部分合作品牌商家</div>
                    <div className='common-inner-text-2'>秉持“全方位、高品质、快速”的服务标准，以客户满意为目标</div>
                    <div style={{ marginTop: 24 }}>
                        <CustomerMobWidget></CustomerMobWidget>
                    </div>
                    <div className='partners'>
                        <PartnersWight></PartnersWight>
                    </div>
                </div>
                <BottomBarMobWidget></BottomBarMobWidget>
                <BottomCallBar></BottomCallBar>
            </div >
        )
    }
}

export default HomeWidgetMobile;