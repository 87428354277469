import React from "react";
import './index.scss';

class PartnersWight extends React.Component {
    constructor() {
        super();
        let pics = [];
        for (let i = 0; i < 17; i++) {
            pics.push(require('assets/h5/partner_' + (i + 1) + '.png'));
        }
        pics.push(require('assets/partner/partner_more.png'));
        this.state = {
            partner: pics,
        }
    }

    render() {
        const { partner } = this.state;
        return (
            <div className='partners-view-mob'>
                <div className='common-inner-text-1 inner-text-1'>合作平台</div>
                <div className='common-inner-text-2'>解决订单处理、物流配送、渠道运营，搭建订单全链路闭环</div>
                <div className='inner-grid' style={{ marginTop: 32 }}>
                    <div className='cu-list grid col-3'>
                        {partner.map((item, index) => {
                            return (
                                <div key={index} className='img-wraper'>
                                    <img src={item} className='inner-image'></img>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default PartnersWight