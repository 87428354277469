import React from "react";
import routerUtils from "js/routerUtils";
import { Loading } from "antd-mobile";

class BaseMobileComponents extends React.Component {
    constructor() {
        super();
        let isMobile = this.isPageMobile() ? 1 : 2;
        if (isMobile == 2) {
            routerUtils.routeTo('/');
        }
    }

    componentDidMount() {
        document.title = this.props.title || '大有单';
    }

    isPageMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag;
    }

    render() {
        let isMobile = this.isPageMobile() ? 1 : 2;
        if (isMobile == 2) {
            setTimeout(() => {
                routerUtils.routeTo('/');
            }, (400));
            return (<div style={{ width: '100%', height: '100vh' }} className="flex-on-center">
                <Loading></Loading>
            </div>)
        } else {
            if (this.renderView) return this.renderView();
            return (<div style={{ width: '100%', height: '100vh' }} className="flex-on-center">
                <Loading></Loading>
            </div>)
        }
    }
}

export default BaseMobileComponents;