import React from "react";
import './bottombar.scoped.scss';
import routerUtils from "js/routerUtils";
import constants from "js/constants";

class BottomBar extends React.Component {
    constructor() {
        super();
        this.state = {
            products: {
                title: '产品',
                list: [{
                    title: '大有单',
                    path: '/product',
                    id: 0,
                }, {
                    title: '订单开放平台',
                    path: '/product',
                    id: 1,
                }, {
                    title: '配送开放平台',
                    path: 'https://peisong.dayoudan.com',
                    type: 'open',
                    id: 2,
                }],
            },
            industy: {
                title: '适用行业',
                list: [{
                    title: '烧烤夜宵',
                    id: 0,
                    path: '/customer'
                }, {
                    title: '餐饮小吃',
                    id: 1,
                    path: '/customer'
                }, {
                    title: '蛋糕甜品',
                    path: '/customer',
                    id: 2,
                }, {
                    title: '浪漫鲜花',
                    id: 3,
                    path: '/customer'
                }, {
                    title: '生鲜果蔬',
                    id: 4,
                    path: '/customer'
                }, {
                    title: '零售商超',
                    id: 5,
                    path: '/customer'
                }],
            },
            about: {
                title: '关于我们',
                list: [{
                    title: '公司介绍',
                    id: 0,
                    path: '/about'
                }, {
                    title: '加入迪嘉',
                    id: 1,
                    path: '/about'
                }]
            },
        }
    }

    onItemsClick(item, index) {
        if (item.path) {
            routerUtils.routeTo(item.path, item.type == 'open');
        }
    }

    render() {
        const { products, industy, about } = this.state;
        return (
            <div className="bottom-bar flex justify-center">
                <div className="heart-width flex-shirk">
                    <div className="flex bottom-module-block">
                        <div className="bottom-block">
                            <div className="flex">
                                <img src={require('../../assets/dayou_logo.png')} className="bottom-dy-logo"></img>
                            </div>
                            <div className="flex margin-top-sm">
                                <div className="flex flex-direction">
                                    <img src={require('../../assets/wechat_service.png')} className="qr-code"></img>
                                    <label className="text-deep-gray text-sm margin-top-sm">扫码关注大有单</label>
                                </div>
                                <div className="flex flex-direction" style={{ marginLeft: '36px' }}>
                                    <img src={require('../../assets/wechat_customer.png')} className="qr-code"></img>
                                    <label className="text-deep-gray text-sm margin-top-sm">扫码联系客服专员</label>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-block flex flex-direction text-left" style={{ marginLeft: '88px' }}>
                            <div className="bottom-text-title">{products.title}</div>
                            {
                                products.list.map(
                                    (child, cindex) => {
                                        return (
                                            <div className="bottom-text-desc" key={child.id}
                                                onClick={this.onItemsClick.bind(this, child, cindex)}>{child.title}</div>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className="bottom-block flex flex-direction text-left" style={{ marginLeft: '88px' }}>
                            <div className="bottom-text-title">{industy.title}</div>
                            {
                                industy.list.map(
                                    (child, cindex) => {
                                        return (
                                            <div className="bottom-text-desc" key={child.id}
                                                onClick={this.onItemsClick.bind(this, child, cindex)}>{child.title}</div>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className="bottom-block flex flex-direction text-left" style={{ marginLeft: '88px' }}>
                            <div className="bottom-text-title">{about.title}</div>
                            {
                                about.list.map(
                                    (child, cindex) => {
                                        return (
                                            <div className="bottom-text-desc" key={child.id}
                                                onClick={this.onItemsClick.bind(this, child, cindex)}>{child.title}</div>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className="flex flex-direction text-left" style={{ marginLeft: '88px' }}>
                            <div className="bottom-text-title" >联系我们</div>
                            <div className="bottom-text-title2" style={{ marginTop: '17px' }}>公司名称</div>
                            <div className="bottom-text-desc" onClick={this.onItemsClick.bind(this, { path: '/about' }, -1)}>湖南迪嘉科技有限公司</div>
                            <div className="bottom-text-title2">客服电话</div>
                            <div className="bottom-text-desc" >{constants.PHONE_NUMBER}</div>
                            <div className="bottom-text-title2">商务合作</div>
                            <div className="bottom-text-desc"  >{constants.PHONE_BUSINESS}</div>
                            <div className="bottom-text-title2">公司地址</div>
                            <div className="bottom-text-desc" onClick={this.onItemsClick.bind(this, { path: '/about' }, -1)}>湖南省长沙市岳麓区世茂希尔顿大楼12楼</div>
                        </div>
                    </div>
                    <div className="copy-right flex">
                        <div className="flex align-center">
                            <img src={require('../../assets/beian.png')} className="logo margin-right-sm"></img>
                            <a className="common-hover" style={{ marginRight: 5 }} href="https://beian.miit.gov.cn/" target="_blank"> 湘ICP备2022000854号-2</a>|
                            <a className="common-hover" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43011102002202">湘公网安备 43011102002202号</a>
                        </div>
                        <div>
                            © 2021~2024 迪嘉科技
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BottomBar;