import React from "react";
import TopBar from '../../common/topbar/topbar';
import BottomAppBar from "pages/home/components/bottomAppBar/bottomAppBar";
import './product.scss';
import constants from "js/constants";
import routerUtils from "js/routerUtils";
import BaseWebComponents from "common/BaseComponents";

class ProductWidget extends BaseWebComponents {
    constructor() {
        super();
        const logo_alpha_yellow = require('assets/more_icons/logo_alpha_yellow.png');
        const logo_alpha_blue = require('assets/more_icons/logo_alpha_blue.png');
        const logo_alpha_cyan = require('assets/more_icons/logo_alpha_cyan.png');
        const logo_alpha_gold = require('assets/more_icons/logo_alpha_gold.png');
        const logo_alpha_green = require('assets/more_icons/logo_alpha_green.png');
        const logo_alpha_orange = require('assets/more_icons/logo_alpha_orange.png');
        const logo_alpha_purple = require('assets/more_icons/logo_alpha_purple.png');
        const logo_alpha_more = require('assets/more_icons/logo_alpha_more.png');


        this.state = {
            more: [{
                title: '小票打印',
                desc: '适配众多小票打印机',
                icon: require('assets/more_icons/more_icon1.png'),
                border: 'rgba(255, 197, 7, 1)',
                logo: logo_alpha_yellow,
            }, {
                title: '免密支付',
                desc: '自动扣费，无需手动支付',
                icon: require('assets/more_icons/more_icon2.png'),
                border: 'rgba(40, 118, 255, 1)',
                logo: logo_alpha_blue,
            }, {
                title: '自动小费',
                desc: '鼓励骑士接单，履约加成',
                icon: require('assets/more_icons/more_icon3.png'),
                border: 'rgba(22, 176, 176, 1)',
                logo: logo_alpha_cyan,
            }, {
                title: '自动分单',
                desc: '连锁多门店，就近分单',
                icon: require('assets/more_icons/more_icon4.png'),
                border: 'rgba(83, 89, 244, 1)',
                logo: logo_alpha_purple,
            }, {
                title: '自动接单',
                desc: '自动向订单平台确认订单',
                icon: require('assets/more_icons/more_icon5.png'),
                logo: logo_alpha_orange,
                border: 'rgba(255, 127, 31, 1)',
            }, {
                title: '数据统计',
                desc: '营收等多维数据统计分析',
                icon: require('assets/more_icons/more_icon6.png'),
                logo: logo_alpha_blue,
                border: 'rgba(40, 118, 255, 1)',
            }, {
                title: '多级账号',
                desc: '主、从账号满足管理需求',
                icon: require('assets/more_icons/more_icon7.png'),
                border: 'rgba(22, 176, 176, 1)',
                logo: logo_alpha_cyan,
            }, {
                title: '运力偏好',
                desc: '选择合作良好的运力配送商',
                icon: require('assets/more_icons/more_icon8.png'),
                border: 'rgba(83, 89, 244, 1)',
                logo: logo_alpha_purple,

            }, {
                title: '地址簿',
                desc: '高频地址管理，发单更便捷',
                icon: require('assets/more_icons/more_icon9.png'),
                border: 'rgba(0, 190, 0, 1)',
                logo: logo_alpha_green,

            }, {
                title: '默认备注',
                desc: '向骑士备注默认信息',
                icon: require('assets/more_icons/more_icon10.png'),
                logo: logo_alpha_gold,
                border: 'rgba(255, 190, 6, 1)',
            }, {
                title: '保价、专送',
                desc: '贵重物品一对一专送',
                icon: require('assets/more_icons/more_icon11.png'),
                logo: logo_alpha_cyan,
                border: 'rgba(22, 176, 176, 1)',
            }, {
                title: '自有配送充值',
                desc: '充值多个配送运力',
                icon: require('assets/more_icons/more_icon12.png'),
                logo: logo_alpha_orange,
                border: 'rgba(255, 127, 31, 1)',
            }, {
                title: '创建订单',
                desc: '解决商家自有配送需求',
                icon: require('assets/more_icons/more_icon13.png'),
                logo: logo_alpha_purple,
                border: 'rgba(83, 89, 244, 1)',
            }, {
                title: '取回商品',
                desc: '一键从顾客方取回商品',
                icon: require('assets/more_icons/more_icon14.png'),
                logo: logo_alpha_cyan,
                border: 'rgba(22, 176, 176, 1)',
            }, {
                title: '更多功能',
                desc: '功能丰富、等你探索',
                icon: require('assets/more_icons/more_icon15.png'),
                logo: logo_alpha_more,
                border: 'rgba(140, 140, 140, 1)',
            }]
        }
    }

    genarateBgColor(color) {
        return color.replace('1)', '0.08)');
    }

    onMouseEnter(item, index) {
        document.body.style.setProperty('--hover-color', item.border);
    }

    toWeb() {
        routerUtils.openPage(constants.REG_DAYOUDAN);
    }

    renderView() {
        const { more } = this.state;
        return (
            <div className="fade">
                <TopBar tabIndex={1}></TopBar>
                <div className="banner-product flex flex-direction">
                    <div className="scroll-handle" style={{ height: '60px' }}></div>
                    <div className="heart-width flex justify-center flex-direction banner-block text-left" style={{ margin: '98px auto auto' }}>
                        <h1 className="main-text">大有产品功能</h1>
                        <p className="p-text">一站式解决订单管理、物流配送、渠道运营，搭建同城订单全链路闭环</p>
                        <div className="btn-free flex-on-center hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                    </div>
                </div>
                <div className="module-width min-heart-width flex justify-center text-left">
                    <div className="heart-width flex justify-center flex-direction block-zero">
                        <div className="flex-on-center title">助力商户降本增效</div>
                        <div className="flex-on-center desc">订单管理难、运力不足、赔付困难等成为行业难题，商家面临增量不增收的困境。</div>
                        <div className="flex frame-view">
                            <div className="frame-view-full">
                                <div className="flex-align-center hit-title">
                                    <img src={require('assets/product/unhappy.png')} className="face-img-top"></img>
                                    <span className="margin-left-sm title-big">商户痛点</span>
                                </div>
                                <div className="flex-align-center hit-title block-margin-top-big">
                                    <img src={require('assets/product/bad.png')} className="face-img"></img>
                                    <span className="margin-left-sm">订单管理难</span>
                                </div>
                                <div className="block-desc">商家如果入驻多个外卖平台，需切换不同平台管理订单资源，营业额结算难，费时又易错。</div>
                                <div className="flex-align-center hit-title block-margin-top">
                                    <img src={require('assets/product/bad.png')} className="face-img"></img>
                                    <span className="margin-left-sm">运力不足</span>
                                </div>
                                <div className="block-desc">高峰期/极端天气情况，骑手资源有限，容易出现无人接单、延时派送的情况，消费者体验差，极易造成客户流失。</div>
                                <div className="flex-align-center hit-title block-margin-top">
                                    <img src={require('assets/product/bad.png')} className="face-img"></img>
                                    <span className="margin-left-sm">赔付困难</span>
                                </div>
                                <div className="block-desc">门店缺少专业人员与运力对接配送问题，餐损赔付问题难处理。</div>
                            </div>
                            <img src={require('assets/product/priduct_frame_right.png')} className="frame-right-img" alt=""></img>
                            <img src={require('assets/product/product_right_logo.png')} className="frame-right-logo" ></img>
                            <div className="frame-view-right">
                                <div className="flex-align-center hit-title">
                                    <img src={require('assets/product/happy.png')} className="face-img-top"></img>
                                    <span className="margin-left-sm title-big">大有单产品优势</span>
                                </div>
                                <div className="flex-align-center hit-title block-margin-top-big">
                                    <img src={require('assets/product/product_icons_1.png')} className="face-img"></img>
                                    <span className="margin-left-sm">一站式管理</span>
                                </div>
                                <div className="block-desc">支持连锁品牌开通多门店子账号，可共用钱包余额，提供智能账单，各门店收支一目了然。</div>
                                <div className="flex-align-center hit-title block-margin-top">
                                    <img src={require('assets/product/product_icons_2.png')} className="face-img"></img>
                                    <span className="margin-left-sm">聚合发单</span>
                                </div>
                                <div className="block-desc">对接多家配送平台，聚合发单，海量骑手在线接单。</div>
                                <div className="flex-align-center hit-title block-margin-top">
                                    <img src={require('assets/product/product_icons_3.png')} className="face-img"></img>
                                    <span className="margin-left-sm">优质服务</span>
                                </div>
                                <div className="block-desc">专业客服7*16h在线处理餐损、售后问题，与各运力合作紧密，可及时与各运力沟通处理门店配送问题。</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="module-width min-heart-width flex justify-center text-left block-one">
                    <div className="heart-width flex justify-center flex-direction">
                        <div className="title flex-on-center">核心功能</div>
                        <div className="desc flex-on-center">SAAS平台，赋能本地生活服务商家</div>
                        <div className="flex block-one-bottom">
                            <div className="flex-sub">
                                <div className="block-one-left flex-sub">
                                    <div className="block-one-bottom-title">全渠道订单整合</div>
                                    <div className="flex align-center margin-top-xl">
                                        <div className="flex-on-center">
                                            <div className="g-dot flex-shirk"></div>
                                        </div>
                                        <div className="module-two-desc text-left">
                                            订单聚合，一站式管理
                                        </div>
                                    </div>
                                    <div className="flex margin-top-sm">
                                        <div className="flex-on-center">
                                            <div className="g-dot flex-shirk"></div>
                                        </div>
                                        <div className="module-two-desc text-left">
                                            配送状态可控、收支清晰可见
                                        </div>
                                    </div>
                                    <div className="flex margin-top-sm">
                                        <div className="flex-on-center">
                                            <div className="g-dot flex-shirk"></div>
                                        </div>
                                        <div className="module-two-desc text-left">
                                            聚合美饿等全渠道订单，实现全网线上订单的统一管理
                                        </div>
                                    </div>
                                </div>
                                <div className="free-main-btn hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                            </div>
                            <img className="block-one-img" src={require('assets/product/product_pic1.png')} />
                        </div>

                    </div>
                </div>
                <div className="module-width min-heart-width flex justify-center text-left block-two">
                    <div className="heart-width flex">
                        <img className="block-two-img" src={require('assets/product/product_pic2.png')} />
                        <div className="block-two-right">
                            <div >
                                <div className="title">订单智能配送</div>
                                <div className="flex margin-top-xl">
                                    <div className="flex-on-center">
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        一呼百应，接得更快，送得更远，省的更多
                                    </div>
                                </div>
                                <div className="flex margin-top-sm">
                                    <div className="flex-on-center">
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        开启十多个配送渠道
                                    </div>
                                </div>
                                <div className="flex margin-top-sm">
                                    <div className="flex-on-center">
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        充值大有单即可呼叫全网骑士。开通免密支付，告别余额管理烦恼
                                    </div>
                                </div>
                                <div className="flex margin-top-sm">
                                    <div className="flex-on-center">
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        支持自有配送账号绑定
                                    </div>
                                </div>
                            </div>
                            <div className="free-main-btn hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                        </div>
                    </div>
                </div>

                <div className="module-width min-heart-width flex justify-center text-left block-three">
                    <div className="heart-width flex justify-center flex-direction">
                        <div className="title flex-on-center">更多功能</div>
                        <div className="desc flex-on-center">百余项功能免费开放任你探索</div>
                        <div className="flex-on-center">
                            <div className="more-square-wraper flex flex-wrap">
                                {
                                    more.map((item, index) => {
                                        return (
                                            <div className="more-square hand" style={{ background: this.genarateBgColor(item.border) }}
                                                onMouseEnter={this.onMouseEnter.bind(this, item, index)} key={index}>
                                                <img className="more-square-img" src={item.icon} />
                                                <div className="more-square-title">{item.title}</div>
                                                <div className="more-square-desc">{item.desc}</div>
                                                <img src={item.logo} className="logo-alpha" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <BottomAppBar></BottomAppBar>
            </div>
        )
    }
}

export default ProductWidget;