import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import constants from 'js/constants';
import routeUtils from 'js/routerUtils';
import TopbarWight from 'h5/compontnts/topbar/topbar';
import BannerTextDeveloper from 'assets/h5/banner_text_developer.svg';
import { Button } from 'antd';
import btnEnterSvg from 'assets/h5/btn_enter.svg';
import BottomCallBar from 'h5/compontnts/bottomCallBar/bottomCallBar';
import phoneGreen from 'assets/h5/phone_green.svg';
import BottomBarMobWidget from 'h5/compontnts/bottombar/bottombar';
import arrowRight from 'assets/h5/arrow-right.svg';
import PartnersWight from 'h5/compontnts/partners';

class DeveloperMobileWight extends BaseMobileComponents {
    constructor() {
        super();
        const phoneBussiness = constants.PHONE_BUSINESS;
        const phoneNumber = constants.PHONE_NUMBER;
        let pics = [];
        pics.push(require('assets/h5/partner_14.png'));
        pics.push(require('assets/h5/partner_9.png'));
        pics.push(require('assets/h5/partner_10.png'));
        pics.push(require('assets/h5/partner_6.png'));
        pics.push(require('assets/h5/partner_7.png'));
        pics.push(require('assets/h5/partner_15.png'));
        pics.push(require('assets/h5/partner_11.png'));
        pics.push(require('assets/h5/partner_16.png'));
        pics.push(require('assets/partner/partner_more.png'));
        this.state = {
            partner: pics,
            phoneBussiness,
            phoneNumber,
            skills: [{
                title: '订单同步',
                content: '同步订单至大有单，自动就近自动分单',
                icon: 'dy_order_desc1.png'
            }, {
                title: '配送回传',
                content: '支持状态订阅，配送状态变化实时回传',
                icon: 'dy_order_desc2.png'
            }, {
                title: '骑士位置',
                content: '实时获取骑士位置，回显到自有系统',
                icon: 'dy_order_desc3.png'
            }],
            skills2: [{
                title: '聚合运力发单',
                content: '多重呼叫策略，兼顾成本时效',
                icon: 'module_two_icon1.png'
            }, {
                title: '多级体系',
                content: '支持商户下统一结算和独立结算',
                icon: 'module_two_icon2.png'
            }, {
                title: '骑士位置',
                content: '实时获取骑士位置，回显到自有系统',
                icon: 'module_two_icon3.png'
            }],
            stepList: [{
                iconTop: 'module_three_icon1.png',
                iconBottom: 'module_three_step1_order.png',
            }, {
                iconTop: 'module_three_icon2.png',
                iconBottom: 'module_three_step2.png',
            }, {
                iconTop: 'module_three_icon3.png',
                iconBottom: 'module_three_step3.png',
            }, {
                iconTop: 'module_three_icon4.png',
                iconBottom: 'module_three_step4.png',
            },],
            stepList2: [{
                iconTop: 'module_three_icon1.png',
                iconBottom: 'module_three_step1.png',
            }, {
                iconTop: 'module_three_icon2.png',
                iconBottom: 'module_three_step2.png',
            }, {
                iconTop: 'module_three_icon3.png',
                iconBottom: 'module_three_step3.png',
            }, {
                iconTop: 'module_three_icon4.png',
                iconBottom: 'module_three_step4.png',
            },],
            envs: [{
                img: require('assets/home_friend_env1.png'),
                title: '业务支撑',
                content: '针对行业定制特有方案',
            }, {
                img: require('assets/home_friend_env2.png'),
                title: '系统稳健',
                content: '日百万级的订单处理能力，保障核心流程便捷操作',
            }, {
                img: require('assets/home_friend_env3.png'),
                title: '服务保障',
                content: '提供1对1的服务对接链路',
            }, {
                img: require('assets/home_friend_env4.png'),
                title: '技术支持',
                content: '协助开发者拉通技术路径，全方位专业服务',
            },],
            bannerBottomBoxs: [{
                title: '运力聚合',
                desc: '具有国内头部运力聚合能力',
                img: require('assets/dev_banner_square1.png')
            }, {
                title: '价格优势',
                desc: '较好的价格优势和统一支付的便利',
                img: require('assets/dev_banner_square2.png')
            }, {
                title: '智能调度',
                desc: '可使用大有的智能调度能力',
                img: require('assets/dev_banner_square3.png')
            },]
        };
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === "micromessenger";
    }

    toDownload() {
        routeUtils.routeTo('/app');
    }

    toDeveloper() {
        routeUtils.routeTo(constants.PEISONG_OPEN_MOBILE);
    }

    renderView() {
        const { stepList, skills, envs, bannerBottomBoxs, skills2, stepList2, phoneBussiness, phoneNumber, partner } = this.state;
        return (
            <div className="root-view-mob-developer bg-white">
                <div className="banner-view">
                    <TopbarWight></TopbarWight>
                    <img src={BannerTextDeveloper} className='banner-text'></img>
                    <div className='sub-text'>面向开发者开放大有单所有的能力：订单管理、物流配送、智能调度</div>
                    <div className='flex-on-center main-color text-sm bold'>
                        <img src={phoneGreen}></img>
                        <span>客服电话：</span>
                        <span>{phoneNumber}</span>
                    </div>
                    <div className='flex-on-center main-color text-sm bold margin-top-xs'>
                        <img src={phoneGreen}></img>
                        <span>商务合作：</span>
                        <span>{phoneBussiness}</span>
                    </div>
                    <div>
                        <Button type='primary' style={{ marginTop: 18 }}>
                            <div className='flex-align-center' onClick={this.toDeveloper.bind(this)}>前往配送开放平台
                                <img src={btnEnterSvg} className='margin-left-xs'></img></div>
                        </Button>
                    </div>
                </div>
                <div className='second-part'>
                    <div className='banner-bottom-boxs'>
                        {
                            bannerBottomBoxs.map((item, index) => {
                                return (
                                    <div className='inner-item' key={index}>
                                        <img className='inner-img' src={item.img}></img>
                                        <div className='inner-title'>{item.title}</div>
                                        <div className='inner-content'>{item.desc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='common-inner-text-1 inner-text-1'>订单开放平台</div>
                    <img src={require('assets/h5/develop_mob_img.png')} className='inner-image'></img>
                    <div className='desc-wraper text-black text-left'>
                        <div className='text-xl bold text-black'>开放平台简介</div>
                        <div className='flex margin-top-xl'>
                            <div className='dot'></div>
                            <div>将您的订单（如美、饿、私域订单）推送到大有单，在大有单内实现订单统一管理、配送呼叫、小票打印等</div>
                        </div>
                        <div className='flex margin-top'>
                            <div className='dot'></div>
                            <div>无缝继承大有单的智能配送呼叫能力，实现回传接口，订单状态变化实时回传。</div>
                        </div>
                    </div>
                    <div className='desc-wraper text-black text-left'>
                        <div className='text-xl bold text-black'>开放能力</div>
                        <div className='flex justify-between align-centeer'>
                            {
                                skills.map((item, index) => {
                                    return (
                                        <div key={index} className='inner-grid-item'>
                                            <img src={require('assets/' + item.icon)} className='inner-icon'></img>
                                            <div className='inner-title'>{item.title}</div>
                                            <div className='inner-content'>{item.content}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='third-part'>
                    <div className='common-inner-text-1 inner-text-1'>接入流程</div>
                    <div className='cu-list grid col-2'>
                        {
                            stepList.map((item, index) => {
                                return (
                                    <div key={index} className='flex-on-center flex-direction'>
                                        <img src={require('assets/' + item.iconTop)} className='icon-top'></img>
                                        <img src={require('assets/' + item.iconBottom)} className='icon-bottom'></img>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='second-next-part'>
                    <div className='common-inner-text-1'>配送开放平台</div>
                    <div className='inner-image-wraper flex-on-center'>
                        <img src={require('assets/h5/peisong.png')} className='inner-image flex-sub'></img>
                    </div>
                    <div className='flex-on-center text-sm bold main-color margin-top-xs' onClick={this.toDeveloper.bind(this)}>前往配送开放平台
                        <img className='margin-left-xs' src={arrowRight}></img>
                    </div>
                    <div className='desc-wraper text-black text-left margin-top'>
                        <div className='text-xl bold text-black'>开放平台简介</div>
                        <div className='flex margin-top-xl'>
                            <div className='dot'></div>
                            <div>依托大有国内头部运力的聚合能力，将全网运力开放给开发者。通过接口，开发者可将配送能力轻松集成到自有系统中，如POS机、仓库、售卖系统、点餐系统等。</div>
                        </div>
                    </div>
                    <div className='desc-wraper text-black text-left'>
                        <div className='text-xl bold text-black'>开放能力</div>
                        <div className='flex justify-between align-centeer'>
                            {
                                skills2.map((item, index) => {
                                    return (
                                        <div key={index} className='inner-grid-item'>
                                            <img src={require('assets/' + item.icon)} className='inner-icon'></img>
                                            <div className='inner-title'>{item.title}</div>
                                            <div className='inner-content'>{item.content}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className='third-part'>
                    <div className='common-inner-text-1 inner-text-1'>接入流程</div>
                    <div className='cu-list grid col-2'>
                        {
                            stepList2.map((item, index) => {
                                return (
                                    <div key={index} className='flex-on-center flex-direction'>
                                        <img src={require('assets/' + item.iconTop)} className='icon-top'></img>
                                        <img src={require('assets/' + item.iconBottom)} className='icon-bottom'></img>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='fouth-part'>
                    <span className='inner-text'>友好的开发环境</span>
                    <div className='cu-list grid col-2' style={{ marginLeft: 6, marginRight: 6, marginTop: 8 }}>
                        {
                            envs.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className='flex flex-direction align-start text-left list-item'>
                                            <img src={item.img} className='icon-top'></img>
                                            <div className='inner-title'>{item.title}</div>
                                            <div className='inner-content'>{item.content}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='partners-view-mob'>
                    <div className='common-inner-text-1 inner-text-1'>部分合作平台</div>
                    <div className='common-inner-text-2'>解决订单处理、物流配送、渠道运营，搭建订单全链路闭环</div>
                    <div className='inner-grid' style={{ marginTop: 32 }}>
                        <div className='cu-list grid col-3'>
                            {partner.map((item, index) => {
                                return (
                                    <div key={index} className='img-wraper'>
                                        <img src={item} className='inner-image'></img>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <BottomBarMobWidget></BottomBarMobWidget>
                <BottomCallBar></BottomCallBar>
            </div>
        )
    }
}

export default DeveloperMobileWight;