import React from "react";
import { Swiper } from "antd-mobile";
import './index.scss';

class CustomerMobWidget extends React.Component {
    constructor() {
        super();
        this.groupSwiper = React.createRef();
        this.state = {
            groupsIndex: 0,
            groups: [{
                name: '煌上煌',
                img: require('assets/h5/home_partner_hsh.png'),
            }, {
                name: '正新鸡排',
                img: require('assets/h5/home_partner_zx.png'),
            }, {
                name: '德克士',
                img: require('assets/h5/home_partner_dicos.png'),
            }, {
                name: '罗莎蛋糕',
                img: require('assets/h5/home_partner_rosa.png'),
            }, {
                name: '天猫小店',
                img: require('assets/h5/home_partner_tmall.png'),
            }, {
                name: '尊宝披萨',
                img: require('assets/h5/home_partner_zb.png'),
            }, {
                name: '橙子便利',
                img: require('assets/h5/home_partner_cz.png'),
            }, {
                name: '芙蓉兴盛',
                img: require('assets/h5/home_partner_furong.png'),
            }],
        }
    }

    onBannerItemClick(item, index) {
        this.groupSwiper.current.swipeTo(index);
    }

    render() {
        const { groups, groupsIndex } = this.state;
        return (
            <div className="customer-mob-widget">
                <div className='cu-list grid col-4'>
                    {groups.map((item, index) => {
                        return (
                            <div key={index}>
                                {
                                    index === 7 ?
                                        (
                                            <div className={`inner-item ${groupsIndex === index ? 'inner-item-on' : ''}`} onClick={this.onBannerItemClick.bind(this, item, index)}>
                                                <img alt="more" src={require('assets/h5/banner_btn_more.png')} className="banner-btn-more"></img>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className={`inner-item ${groupsIndex === index ? 'inner-item-on' : ''}`} onClick={this.onBannerItemClick.bind(this, item, index)}>{item.name}</div>

                                        )
                                }
                            </div>
                        )
                    })}
                    
                </div>
                <div className='margin-top-sm'>
                    <Swiper ref={this.groupSwiper} onIndexChange={(i) => this.setState({ groupsIndex: i })} stuckAtBoundary={false} slideSize={62} defaultIndex={0} style={{
                        '--track-padding': ' 0 0 16px',
                        '--active-dot-color': 'white',
                        '--dot-spacing': '8px',
                    }} autoplay={true} loop={true}>
                        {
                            groups.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <div className='padding flex-on-center'>
                                            <img alt="banner" src={item.img} style={{ width: 228, height: 290 }}></img>
                                        </div>
                                    </Swiper.Item>
                                )
                            })
                        }

                    </Swiper>
                </div>
            </div>
        )
    }
}

export default CustomerMobWidget;