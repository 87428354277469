import React from "react";
import iconClose from 'assets/h5/close.svg';
import './menuPage.scss';
import withRouter from "common/withRouter/withRouter";
import routerUtils from "js/routerUtils";

class MenuPage extends React.Component {
    constructor() {
        super()
        this.state = {
            menus: [{
                title: '首页',
                path: '/h5/index'
            }, {
                title: '产品功能',
                path: '/h5/product'
            }, {
                title: '客户案例',
                path: '/h5/customer'
            }, {
                title: '开放平台',
                path: '/h5/developer'
            }, {
                title: '关于我们',
                path: '/h5/about'
            }]
        }
    }

    onItemClick(item) {
        routerUtils.routeTo(item.path)
    }

    render() {
        const { menus } = this.state;
        return (
            <div className="menu-page-root" style={{ height: this.props.isOpen ? '100%' : '0px', overflow: 'hidden', zIndex: this.props.isOpen ? '99' : '33' }}>
                <div className='mobile-top-bar-menu padding-lr'>
                    <img src={require('assets/h5/dy_logo.png')} className='logo-left'></img>
                    {
                        this.props.isOpen ? (
                            <img src={iconClose} className='more' onClick={this.onCloseClick.bind(this)}></img>
                        ) : (
                            <div></div>
                        )
                    }
                </div>
                <div className="menus">
                    {
                        menus.map((item, index) => {
                            return (
                                <div key={index} className={`${this.props.location.pathname == item.path ? 'item-on' : ''} inner-item`} onClick={this.onItemClick.bind(this, item)}>
                                    {this.props.location.pathname == item.path && <div className="item-line"></div>} {item.title}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    onCloseClick() {
        this.props.openMenu(false);
    }
}

export default withRouter(MenuPage);