import React, { useEffect, useRef, useState } from 'react';
import './waterScroller.scss';

class WaterScroller extends React.Component {
    constructor() {
        super();
        // 滚动速度，值越小，滚动越快
        this.warper = React.createRef();
        this.childDom1 = React.createRef();
        this.childDom2 = React.createRef();

        const imgNames = [];
        for (let i = 0; i < 9; i++) {
            imgNames.push(require('assets/water_fall/water_img_' + i + '.png'));
        }

        this.state = {
            isScrolle: false,
            list: [],
            speed: 60,
            warper: null,
            imgNames
        }
    }

    componentDidMount() {
        const names = this.state.imgNames;
        if (this.props.index == 1) {
            const listTemplate = [names[0], names[1], names[2]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 60
            })
        }
        else if (this.props.index == 2) {
            const listTemplate = [names[3], names[4], names[5]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 40
            })
        } else {
            const listTemplate = [names[6], names[7], names[8]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 63
            })
        }

        // if (!this.isScrolle) {
        //     this.isScrolle = true;
        //     let prop = this.props;
        //     this.setState({
        //         speed: prop.index == 1 ? 60 : prop.index == 2 ? 40 : 63
        //     })
        //     setTimeout(() => {
        //         this.onMouseHover(true)
        //     }, 1000);
        // }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    onMouseHover(isLeave, isMouse) {
        if (isMouse) {
            this.setState({
                isScrolle: !isLeave
            })
        }

        if (isLeave) {
            if (this.timer)
                clearTimeout(this.timer);
            this.timer = setInterval(
                () =>
                    this.warper.current.scrollTop >= this.childDom1.current.scrollHeight
                        ? (this.warper.current.scrollTop = 0)
                        : this.warper.current.scrollTop++,
                this.state.speed
            );
            // 多拷贝一层，让它无缝滚动
            // this.childDom2.current.innerHTML = this.childDom1.current.innerHTML;
        } else {
            if (this.timer)
                clearTimeout(this.timer);
        }
    }
    // onMouseOver={this.onMouseHover.bind(this, false, true)}
    // onMouseLeave={this.onMouseHover.bind(this, true, true)}
    render() {
        const { list } = this.state;
        return (
            // <div className='h-section-part2-plots-top'>
            //     <div className='parent' ref={this.warper}>
            //         <div className='child' ref={this.childDom1}>
            //             {list.map((item, index) => (
            //                 <img src={item} className='w-r-img-item' key={index}></img>
            //             ))}
            //         </div>
            //     </div>
            // </div>
            <div className="water-scroller-left">
                <div className="ws-parent-left">
                    {list.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item} className='w-r-img-item' key={index}></img>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
export default WaterScroller;
