import React from "react";
import TopBar from '../../common/topbar/topbar';
import BottomAppBar from "pages/home/components/bottomAppBar/bottomAppBar";
import './developer.scss';
import constants from "js/constants";
import phoneImage from 'assets/svg/phone.svg';
import phoneBusinessImage from 'assets/svg/phone_business_dev.svg';
import routeUtils from "js/routerUtils";
import BaseWebComponents from "common/BaseComponents";
import BottomBar from "common/bottombar/bottombar";

class DeveloperWidget extends BaseWebComponents {
    constructor() {
        super();
        this.state = {
            partners: [{
                img: require('assets/partners1.png'),
            }, {
                img: require('assets/partners2.png'),
            }, {
                img: require('assets/partners3.png'),
            }, {
                img: require('assets/partners4.png'),
            }, {
                img: require('assets/partners5.png'),
            }, {
                img: require('assets/partners6.png'),
            }, {
                img: require('assets/partners7.png'),
            }, {
                img: require('assets/partners8.png'),
            },],

            moduleThreeList: [{
                isArrow: false,
                topImg: require('assets/module_three_icon1.png'),
                bottomImg: require('assets/module_three_step1.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon2.png'),
                bottomImg: require('assets/module_three_step2.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon3.png'),
                bottomImg: require('assets/module_three_step3.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon4.png'),
                bottomImg: require('assets/module_three_step4.png'),
            },],

            dyOrderFlowList: [{
                isArrow: false,
                topImg: require('assets/module_three_icon1.png'),
                bottomImg: require('assets/module_three_step1_order.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon2.png'),
                bottomImg: require('assets/module_three_step2.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon3.png'),
                bottomImg: require('assets/module_three_step3.png'),
            }, {
                isArrow: true,
            }, {
                isArrow: false,
                topImg: require('assets/module_three_icon4.png'),
                bottomImg: require('assets/module_three_step4.png'),
            },],

            envs: [{
                img: require('assets/home_friend_env1.png'),
                title: '业务支撑',
                content: '针对行业定制特有方案',
            }, {
                img: require('assets/home_friend_env2.png'),
                title: '系统稳健',
                content: '日百万级的订单处理能力，保障核心流程便捷操作',
            }, {
                img: require('assets/home_friend_env3.png'),
                title: '服务保障',
                content: '提供1对1的服务对接链路',
            }, {
                img: require('assets/home_friend_env4.png'),
                title: '技术支持',
                content: '协助开发者拉通技术路径，全方位专业服务',
            },],

            bannerBottomBoxs: [{
                title: '运力聚合',
                desc: '具有国内头部运力聚合能力',
                img: require('assets/dev_banner_square1.png')
            }, {
                title: '价格优势',
                desc: '较好的价格优势和统一支付的便利',
                img: require('assets/dev_banner_square2.png')
            }, {
                title: '智能调度',
                desc: '可使用大有的智能调度能力',
                img: require('assets/dev_banner_square3.png')
            },]
        }
    }

    naviToPath(route) {
        routeUtils.routeTo(route);
    }

    toPeisongOpenPage() {
        routeUtils.openPEISONG();
    }

    renderView() {
        const { partners, moduleThreeList, envs, bannerBottomBoxs, dyOrderFlowList } = this.state;
        return (
            <div className="fade">
                <TopBar tabIndex={3}></TopBar>
                <div className="banner-wraper">
                    <div className="banner flex flex-direction">
                        <div className="scroll-handle" style={{ height: '60px' }}></div>
                        <div className="heart-width flex justify-center flex-direction banner-block text-left" style={{ margin: '98px auto auto' }}>
                            <h1 className="main-text" >大有开放平台</h1>
                            <p className="p-text">面向开发者开放大有单所有的能力：订单管理、物流配送、智能调度</p>
                            <div className="main-color phone-number flex-align-center">
                                <img src={phoneImage} className="phone-img margin-right-sm"></img>
                                客服电话
                                <span className="margin-left-sm">{constants.PHONE_NUMBER}</span>
                            </div>
                            <div className="main-color phone-number flex-align-center margin-top-xs">
                                <img src={phoneBusinessImage} className="phone-img margin-right-sm"></img>
                                商务合作
                                <span className="margin-left-sm">{constants.PHONE_BUSINESS}</span>
                            </div>
                            <div className="btn-delivery-platform hand" onClick={this.toPeisongOpenPage.bind(this)}>前往配送开放平台</div>
                        </div>
                    </div>
                    <div className="banner-bottom-fixer"></div>
                    <div className="banner-bottom-boxs min-heart-width">
                        <div className="flex-on-center heart-width">
                            {
                                bannerBottomBoxs.map((item, index) => {
                                    return (
                                        <div className="banner-bottom-box flex-align-center" key={index}>
                                            <img src={item.img} className="banner-bottom-box-img" />
                                            <div>
                                                <div className="banner-bottom-box-title">{item.title}</div>
                                                <div className="banner-bottom-box-desc">{item.desc}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>


                <div className="module-width min-heart-width flex justify-center  module-order-block">
                    <div className="heart-width flex justify-center flex-direction">
                        <div className="big-title flex-on-center">订单开放平台</div>
                        <div className="flex">
                            <img src={require('assets/dy_order.png')} className="module-order-img" />
                            <div className="module-order-left flex-sub">
                                <div className="module-order-big-title">开放平台简介</div>
                                <div className="flex margin-top-xl">
                                    <div>
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        将您的订单（如美、饿、私域订单）推送到大有单，在大有单内实现订单统一管理、配送呼叫、小票打印等。
                                    </div>
                                </div>
                                <div className="flex margin-top-sm">
                                    <div>
                                        <div className="g-dot flex-shirk"></div>
                                    </div>
                                    <div className="module-two-desc text-left">
                                        无缝继承大有单的智能配送呼叫能力，实现回传接口，订单状态变化实时回传。
                                    </div>
                                </div>
                                <div className="module-order-big-title two">开放能力</div>
                                <div className="flex margin-top-xl text-left" style={{ fontSize: 14 }}>
                                    <div className="flex-sub three-box flex">
                                        <img src={require('assets/dy_order_desc1.png')} className="module-two-icons" />
                                        <div>
                                            <div className="module-two-title">订单同步</div>
                                            <div className="module-two-desc">同步订单至大有单，自动就近自动分单</div>
                                        </div>
                                    </div>
                                    <div className="flex-sub three-box margin-left flex">
                                        <img src={require('assets/dy_order_desc2.png')} className="module-two-icons" />
                                        <div>
                                            <div className="module-two-title">配送回传</div>
                                            <div className="module-two-desc">支持状态订阅，配送状态变化实时回传</div>
                                        </div>
                                    </div>
                                    <div className="flex-sub three-box margin-left flex">
                                        <img src={require('assets/dy_order_desc3.png')} className="module-two-icons" />
                                        <div>
                                            <div className="module-two-title">骑士位置</div>
                                            <div className="module-two-desc">实时获取骑士位置，回显到自有系统</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="module-width module-three min-heart-width flex justify-center">
                    <div className="heart-width">
                        <div className="title-l1 flex-on-center">接入流程</div>
                        <div className="title-l2 flex-on-center">高效稳定的服务，助力业务的发展</div>
                        <div className="bottom flex-on-center">
                            {
                                dyOrderFlowList.map((item, index) => {
                                    return (
                                        <div key={index} style={item.style}>
                                            {
                                                item.isArrow ?
                                                    (
                                                        <div>
                                                            <img className="arrow-img" src={require('assets/module_three_jiantou.png')} />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="step-view flex-on-center flex-direction">
                                                            <img className="img-top" src={item.topImg} />
                                                            <img className="img-bottom" src={item.bottomImg} />
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="dev-from-open">
                    <div className="module-width min-heart-width flex justify-center  module-two-block text-left">
                        <div className="heart-width flex justify-center flex-direction">
                            <div className="big-title flex-on-center">配送开放平台</div>
                            <div className="flex">
                                <div className="module-one-left flex-sub">
                                    <div className="module-two-big-title">开放平台简介</div>
                                    <div className="flex margin-top-xl">
                                        <div>
                                            <div className="g-dot flex-shirk"></div>
                                        </div>
                                        <div className="module-two-desc text-left">
                                            依托大有国内头部运力的聚合能力，将全网运力开放给开发者。通过接口，开发者可将配送能力轻松集成到自有系统中，如POS机、仓库、售卖系统、点餐系统等。
                                        </div>
                                    </div>
                                    <div className="module-two-big-title two">开放能力</div>
                                    <div className="flex margin-top-xl ">
                                        <div className="flex-sub three-box flex">
                                            <img src={require('assets/module_two_icon1.png')} className="module-two-icons" />
                                            <div>
                                                <div className="module-two-title">聚合运力智能发单</div>
                                                <div className="module-two-desc">多重呼叫策略，兼顾成本时效</div>
                                            </div>
                                        </div>
                                        <div className="flex-sub three-box margin-left flex">
                                            <img src={require('assets/module_two_icon2.png')} className="module-two-icons" />
                                            <div>
                                                <div className="module-two-title">多级体系</div>
                                                <div className="module-two-desc">支持商户下统一结算和独立结算</div>
                                            </div>
                                        </div>
                                        <div className="flex-sub three-box margin-left flex">
                                            <img src={require('assets/module_two_icon3.png')} className="module-two-icons" />
                                            <div>
                                                <div className="module-two-title">骑士位置</div>
                                                <div className="module-two-desc">实时获取骑士位置，回显到自有系统</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-color hand flex-align-center margin-top-xl bold text-xl" onClick={this.toPeisongOpenPage.bind(this)}>前往配送开放平台
                                        <img style={{ height: 20, marginLeft: 2 }} src={require('assets/arrow_right.png')}></img> </div>
                                </div>
                                <img src={require('assets/index_info_one.png')} className="module-two-img" />
                            </div>
                        </div>
                    </div>

                    <div className="module-width module-three min-heart-width flex justify-center">
                        <div className="heart-width">
                            <div className="title-l1 flex-on-center">接入流程</div>
                            <div className="title-l2 flex-on-center">高效稳定的服务，助力业务的发展</div>
                            <div className="bottom flex-on-center">
                                {
                                    moduleThreeList.map((item, index) => {
                                        return (
                                            <div key={index} style={item.style}>
                                                {
                                                    item.isArrow ?
                                                        (
                                                            <div>
                                                                <img className="arrow-img" src={require('assets/module_three_jiantou.png')} />
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="step-view flex-on-center flex-direction">
                                                                <img className="img-top" src={item.topImg} />
                                                                <img className="img-bottom" src={item.bottomImg} />
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="module-width module-four min-heart-width flex justify-center">
                        <div className="heart-width">
                            <div className="content-big-title flex-on-center">部分合作平台</div>
                            <div className="small-title flex-on-center margin-top-sm">解决订单处理、物流配送、渠道运营，搭建订单全链路闭环</div>
                            <div className="flex justify-center">
                                <div className="flex flex-wrap partner-pics">
                                    {
                                        partners.map((item, index) => {
                                            return (
                                                <img src={item.img} key={index} ></img>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="module-width module-five min-heart-width flex justify-center">
                        <div className="heart-width">
                            <div className="content-big-title flex-on-center">友好的开发环境</div>
                            <div className="flex justify-center list-wraper">
                                <div className="flex flex-wrap justify-center text-left">
                                    {envs.map((item, index) => {
                                        return (
                                            <div className="env-block flex align-center" key={index} >
                                                <img src={item.img} className="env-img" />
                                                <div className="env-block-right">
                                                    <div className="list-title">{item.title}</div>
                                                    <div className="list-content">{item.content}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <BottomAppBar></BottomAppBar>
            </div >
        )
    }
}

export default DeveloperWidget;