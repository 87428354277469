import React from "react";
import './home.scss';
import WaterScroller from "./components/waterScroller/waterScroller";
import BottomAppBar from './components/bottomAppBar/bottomAppBar';
import TopBar from '../../common/topbar/topbar';
import QuickNumber from "./components/quickNumber";
import HorizontalScroller from "./components/horizontalScroller/horizontalScroller";
import WaterScrollerCenter from "./components/waterScrollerCenter/waterScroller";
import WaterScrollerRight from "./components/waterScrollerRight/waterScroller";

import videoBtn1 from 'assets/svg/video_btn1.svg';
import videoBtn1On from 'assets/svg/video_btn1_on.svg';
import videoBtn2 from 'assets/svg/video_btn2.svg';
import videoBtn2On from 'assets/svg/video_btn2_on.svg';
import videoBtn3 from 'assets/svg/video_btn3.svg';
import videoBtn3On from 'assets/svg/video_btn3_on.svg';

import constants from 'js/constants';
import routerUtils from "js/routerUtils";
import BaseWebComponents from "common/BaseComponents";


class HomeWidget extends BaseWebComponents {
    constructor() {
        super();
        this.state = {
            countinglist: [{
                id: 0,
                countNum: 10,
                countUnit: '万+',
                desc: '服务商家数',
                icon: require('../../assets/counting_icon_1.png')
            }, {
                id: 1,
                countNum: 9,
                countUnit: '分钟',
                desc: '平均到店',
                icon: require('../../assets/counting_icon_2.png')
            }, {
                id: 2,
                countNum: 28,
                countUnit: '分钟',
                desc: '平均送达',
                icon: require('../../assets/counting_icon_3.png')
            }, {
                id: 3,
                countNum: 400,
                countUnit: '座+',
                desc: '开通城市',
                icon: require('../../assets/counting_icon_4.png')
            }, {
                id: 4,
                countNum: 100,
                countUnit: '万+',
                desc: '日处理订单',
                icon: require('../../assets/counting_icon_5.png')
            }],
            defaultOpenFrameIndex: 0,
            frameList: [{
                title: '餐饮',
                bgColor: '#16B0B0',
                img: require('assets/home_square_pics/home_sq1.png'),
                desc: '高端餐饮、快餐、面食',
            }, {
                title: '鲜花',
                bgColor: '#FF5F00',
                img: require('assets/home_square_pics/home_sq2.png'),
                desc: '绿植、鲜花、蛋糕',
            }, {
                title: '商超',
                bgColor: '#00CB41',
                img: require('assets/home_square_pics/home_sq3.png'),
                desc: '超市、商场、便利店、炒货',
            }, {
                title: '医药',
                bgColor: '#009FFF',
                desc: '药品、成人用品、保健品',
                img: require('assets/home_square_pics/home_sq4.png'),
            }, {
                title: '生鲜',
                bgColor: '#8416B0',
                desc: '水果、蔬菜、生鲜',
                img: require('assets/home_square_pics/home_sq5.png'),
            },],
            stepList: [{
                title: '1.下载注册',
                desc: '应用市场下载大有单并注册',
                icon: videoBtn1,
                iconUnfocus: videoBtn1On,
            }, {
                title: '2.绑定渠道',
                desc: '绑定美饿等线上渠道同步订单',
                icon: videoBtn2,
                iconUnfocus: videoBtn2On,
            }, {
                title: '3.呼叫配送',
                desc: '自动开通全网运力，全网比价呼单，自动回传',
                icon: videoBtn3,
                iconUnfocus: videoBtn3On,
            }],
            focusStepIndex: 0,
            applist: [{
                title: 'iOS',
                url: '',
                img: '',
                imgFocus: '',
                svg: <svg t="1684751371492" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1946" width="128" height="128"><path d="M724.384 428.64l82.784-117.856c4.896-7.008 3.68-16.192-2.752-20.544-6.4-4.352-15.616-2.176-20.512 4.8l-85.984 122.464A515.712 515.712 0 0 0 512 383.36c-66.304 0-129.28 12.224-185.92 34.112L240.096 295.04c-4.896-7.04-14.08-9.184-20.512-4.832-6.4 4.352-7.648 13.536-2.72 20.544l82.752 117.856C168.032 488.704 75.744 602.816 64 736h896c-11.744-133.184-104.032-247.296-235.616-307.36zM323.008 614.4c-27.392 0-49.6-21.76-49.6-48.64 0-26.848 22.208-48.64 49.6-48.64 27.36 0 49.568 21.792 49.568 48.64 0 26.88-22.208 48.64-49.6 48.64z m384.192 0c-27.36 0-49.568-21.76-49.568-48.64 0-26.848 22.176-48.64 49.6-48.64 27.36 0 49.536 21.792 49.536 48.64 0 26.88-22.176 48.64-49.568 48.64z" fill="#272636" p-id="1947"></path></svg>
            }, {
                title: 'iOS',
                url: '',
            },]
        };
    }

    getRandomNum(len) {
        let point = ''
        for (let i = 1; i <= len; i++) {
            point = point + parseInt(Math.random() * 10)
        }
        return point
    }

    mouseEnterLister(item, index) {
        this.setState({
            defaultOpenFrameIndex: index
        });
    }

    mouseStepEnterLister(item, index) {
        this.setState({
            focusStepIndex: index
        })
    }

    toWeb() {
        routerUtils.openPage(constants.REG_DAYOUDAN);
    }

    toProduct() {
        routerUtils.routeTo('/product');
    }

    renderView() {
        const { countinglist, defaultOpenFrameIndex, frameList, stepList, focusStepIndex, applist } = this.state;
        return (
            <div className="fade">
                <TopBar tabIndex={0}></TopBar>
                <div >
                    <div className="first-flow">
                        <div className="banner flex flex-direction">
                            <div className="scroll-handle" style={{ height: '60px' }}></div>
                            <div className="heart-width flex justify-center flex-direction banner-block text-left" style={{ margin: '98px auto auto' }}>
                                <h1 className="main-text" >大有单·本地生活聚合服务商</h1>
                                <p className="p-text">专注于全渠道聚合订单管理与全链路配送服务</p>
                                <div className="btn-free flex-on-center hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                            </div>
                        </div>
                        <div className="fit-height-tag" style={{ height: '50px' }}></div>

                        <div className="module-width min-heart-width flex justify-center">
                            <div className="counting-bar-wraper heart-width">
                                <div className="flex align-center">
                                    <div className="flex flex-shirk counting-bar align-center" style={{ minWidth: 1066, boxSizing: 'border-box' }}>
                                        {
                                            countinglist.map(
                                                (list, index) => {
                                                    return (
                                                        <div className="square" key={list.id}>
                                                            <div className="flex-on-center" >
                                                                <img src={list.icon}></img>
                                                                <div className="text-left">
                                                                    <div className="">
                                                                        <label>
                                                                            <QuickNumber maxNum={list.countNum} key={index} index={index}></QuickNumber>
                                                                        </label>
                                                                        <span>{list.countUnit}</span>
                                                                    </div>
                                                                    <div className="count-desc">{list.desc}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flow-wraper">
                        <div className="second-flow heart-width margin-auto">
                            <div className="title">大有单解决方案</div>
                            <p className="desc">SAAS平台，赋能本地生活服务商家</p>
                            <img src={require('../../assets/dy_content_pic.png')} className="home-dy-bg heart-width"></img>
                            <div className="flex-on-center">
                                <div className="btn-detail flex-on-center hand" onClick={this.toProduct.bind(this)}>了解详情</div>
                            </div>
                        </div>
                    </div>
                    <div className="flow-wraper">
                        <div className="third-flow heart-width margin-auto">
                            <div className="title">各行各业，都能适用大有单</div>
                            <p className="desc">适配餐饮、鲜花蛋糕、果蔬生鲜、商超、医药等行业</p>
                            <div className="flex-on-center horizontal-block">
                                {
                                    frameList.map(
                                        (item, index) => {
                                            return (
                                                <div className={`frame-square  ${defaultOpenFrameIndex === index ? 'square-open' : 'square-style'}`} style={{ background: item.bgColor }} key={index}
                                                    onMouseEnter={this.mouseEnterLister.bind(this, item, index)}>
                                                    <div className="flex align-center flow-item-wraper" style={{ display: defaultOpenFrameIndex === index ? 'flex' : 'none' }}>
                                                        <div className="flow-item-bg-left">
                                                            <img className="bg-left-img" src={item.img} alt={item.title} />
                                                        </div>
                                                        <div className="text-left flex-sub flow-item-right" style={{ minWidth: 240 }}>
                                                            <div className="flow-item-right-title">{item.title}</div>
                                                            <div className="flow-item-right-desc">{item.desc}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-direction align-center" style={{ display: defaultOpenFrameIndex === index ? 'none' : 'flex' }}>
                                                        <div className="frame-square-unfocus-text" style={{ width: '100%' }}>
                                                            {item.title}
                                                        </div>
                                                        <div className="flex-sub"></div>
                                                        <img src={require('../../assets/home_square_pics/home_squares_logo.png')} className="trans-logo flex-shirk" style={{ margintop: 4 }} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="module-width min-heart-width flex justify-center fourth-flow">
                        <div className="heart-width flex justify-center flex-direction">
                            <div>
                                <label>3</label>
                                <span>步拥有大有单</span>
                            </div>
                            <p>百余项功能免费开放中，简单3步，轻松拥有。</p>
                        </div>
                    </div>
                    <div className="module-width min-heart-width flex justify-center video-parent-frame-wraper">
                        <div className="video-parent-frame heart-width flex " style={{ paddingLeft: 31, paddingRight: 91 }}>
                            <div className="video-left">
                                {
                                    focusStepIndex === 0 ? <video loop muted src={require('../../assets/video/video_one.mp4')} className="video-tag" height="427" autoPlay />
                                        : focusStepIndex === 1 ? <video loop muted src={require('../../assets/video/video_two.mp4')} className="video-tag" height="427" autoPlay />
                                            : <video loop muted src={require('../../assets/video/video_three.mp4')} className="video-tag" height="427" autoPlay />
                                }
                            </div>
                            <div className="video-right">
                                {
                                    stepList.map(
                                        (item, index) => {
                                            return (
                                                <div className={`step-item ${focusStepIndex === index ? 'step-item-on' : ''}`} key={index}
                                                    onMouseEnter={this.mouseStepEnterLister.bind(this, item, index)}>
                                                    <img src={focusStepIndex === index ? item.iconUnfocus : item.icon} className="icon-step-round" alt=""></img>
                                                    <div className="text-left step-right">
                                                        <div className="step-title">{item.title}</div>
                                                        <div className="step-desc">{item.desc}</div>
                                                    </div>
                                                    <div className={`step-number f0`}>0{index + 1}</div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="module-width min-heart-width flex justify-center">
                        <div className="heart-width flex justify-center water-fall-view">
                            <div className="water-fall-view-left text-left">
                                <div className="w-title-l1">部分合作品牌商家</div>
                                <div className="w-title-l2 margin-top-xl margin-right">秉持“全方位、高品质、快速”的服务标准，以客户满意为目标</div>
                                <div className="w-button flex-on-center margin-top-xxl hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                                <img className="w-l-logo" src={require('../../assets/w_l_logo.png')}></img>
                            </div>
                            <div className="water-fall-view-right flex" style={{ width: 708 }}>
                                <div className="w-scroller">
                                    <WaterScroller index={1} key={1}></WaterScroller>
                                </div>
                                <div className="w-scroller">
                                    <WaterScrollerCenter index={2} key={2}></WaterScrollerCenter>
                                </div>
                                <div className="w-scroller">
                                    <WaterScrollerRight index={3} key={3}></WaterScrollerRight>
                                </div>
                                <div className="white-paper">
                                    <div className="white-top"></div>
                                    <div className="white-bottom"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <HorizontalScroller></HorizontalScroller>
                    <BottomAppBar></BottomAppBar>
                </div>
            </div>
        )
    }
}

export default HomeWidget;