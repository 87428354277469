import React, { useEffect, useRef, useState } from 'react';
import './waterScroller.scss';

class WaterScrollerRight extends React.Component {
    constructor() {
        super();
        // 滚动速度，值越小，滚动越快
        this.warper = React.createRef();
        this.childDom1 = React.createRef();
        this.childDom2 = React.createRef();

        const imgNames = [];
        for (let i = 0; i < 9; i++) {
            imgNames.push(require('assets/water_fall/water_img_' + i + '.png'));
        }

        this.state = {
            isScrolle: false,
            list: [],
            speed: 60,
            warper: null,
            imgNames
        }
    }

    componentDidMount() {
        const names = this.state.imgNames;
        if (this.props.index == 1) {
            const listTemplate = [names[0], names[1], names[2]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 60
            })
        }
        else if (this.props.index == 2) {
            const listTemplate = [names[3], names[4], names[5]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 40
            })
        } else {
            const listTemplate = [names[6], names[7], names[8]];
            let list = [];
            for (let i = 0; i < 20; i++) {
                list = list.concat(listTemplate);
            }
            this.setState({
                list,
                speed: 63
            })
        }
    }

    render() {
        const { list } = this.state;
        return (
            <div className="water-scroller-right">
                <div className="ws-parent-right">
                    {list.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item} className='w-r-img-item' key={index}></img>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
export default WaterScrollerRight;
