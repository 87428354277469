import './topbar.scss';
import React from 'react';
import MenuPage from './menuPage';
import { Button } from 'antd-mobile';
import { ArrowUpOutlined } from '@ant-design/icons';

class TopbarWight extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            toWhite: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleMobileScroll, true) // 监听页面滚动
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleMobileScroll, true);
    }

    handleMobileScroll = (e) => {
        if (e.target.scrollTop > 10) {
            this.setState({
                toWhite: true,
            })
        } else {
            this.setState({
                toWhite: false,
            })
        }
    }

    toTopClick() {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        // let timer = setInterval(() => {
        //     let top = document.documentElement.scrollTop || document.body.scrollTop;
        //     window.scrollTo(0, top - top / 5);
        //     if (top <= 0) {
        //         clearInterval(timer);
        //     }
        // }, 30);
    }

    render() {
        const { isOpen, toWhite } = this.state;
        return (
            <div>
                <div style={{ height: 50 }}></div>
                <div className='to-top-btn flex-on-center text-white' style={{ display: toWhite && !isOpen ? 'flex' : 'none' }} onClick={this.toTopClick.bind(this)}>
                    <ArrowUpOutlined></ArrowUpOutlined>
                </div>
                <MenuPage isOpen={isOpen} openMenu={this.openMenu} ></MenuPage>
                <div className={`mobile-top-bar padding-lr ${toWhite ? 'shadow-deep' : 'shadow-line'}`} style={{ overflow: 'hidden' }}>
                    <img src={require('assets/h5/dy_logo.png')} className='logo-left'></img>
                    {
                        isOpen ? '' : (
                            <img src={require('assets/h5/more.png')} className='more' onClick={this.openMenu.bind(this, true)}></img>
                        )
                    }
                </div>
            </div >
        )
    }

    openMenu = (isOpen) => {
        this.setState({
            isOpen
        })
    }
}

export default TopbarWight;