import React from "react";
import TopBar from '../../common/topbar/topbar';
import BottomAppBar from "pages/home/components/bottomAppBar/bottomAppBar";
import './customer.scss';
import constants from "js/constants";
import routerUtils from "js/routerUtils";
import BaseWebComponents from "common/BaseComponents";

class CustomerWidget extends BaseWebComponents {
    constructor() {
        super();
        const evNameFixs = [3, 7, 1, 8, 6, 0, 2, 4];
        const exmpleList = [];
        evNameFixs.forEach((item) => {
            exmpleList.push({
                pic: require('../../assets/water_fall/water_img_' + (item) + '.png')
            })
        })

        const brands = [];
        for (let i = 1; i < 13; i++) {
            brands.push({
                pic: require('../../assets/customer_pics/c_p_' + i + '.png')
            });
        }

        this.state = {
            exmpleList,
            currentExampleIndex: -1,
            brands
        }
    }

    mouseEnterGallery(index) {
        this.setState({
            currentExampleIndex: index
        });
    }

    mounseLeave(index) {
        this.setState({
            currentExampleIndex: -1
        });
    }

    toWeb() {
        routerUtils.openPage(constants.REG_DAYOUDAN)
    }

    renderView() {
        const { exmpleList, currentExampleIndex, brands } = this.state;
        return (
            <div className="fade">
                <TopBar tabIndex={2}></TopBar>
                <div className="banner-customer flex flex-direction">
                    <div className="scroll-handle" style={{ height: '60px' }}></div>
                    <div className="heart-width flex justify-center flex-direction banner-block text-left" style={{ margin: '98px auto auto' }}>
                        <h1 className="main-text" >大有客户案例</h1>
                        <p className="p-text">每一次服务，都在为业绩增长创新助力，在向美好生活前行</p>
                        <div className="btn-free flex-on-center hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                    </div>
                </div>
                <div className="module-width min-heart-width flex justify-center page-customer-example-view">
                    <div className="heart-width flex justify-center flex-direction">
                        <div className="example-view-title">客户案例</div>
                        <div className="example-view-desc">突破传统低效配送流程，步入智能配送新时代</div>
                        <div className="example-view-gallery flex flex-wrap justify-center">
                            {
                                exmpleList.map((item, index) => {
                                    return (
                                        <div key={index} className={`img-animal-view ${index == currentExampleIndex ? 'animal-hover' : ''}`}
                                            onMouseEnter={this.mouseEnterGallery.bind(this, index)}
                                            onMouseLeave={this.mounseLeave.bind(this, index)}>
                                            <div className="flex">
                                                <img src={item.pic} className="example-view-img"
                                                ></img>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex-on-center">
                            <div className="btn-free flex-on-center hand" onClick={this.toWeb.bind(this)}>免费注册</div>
                        </div>
                    </div>
                </div>

                <div className="module-width min-heart-width page-customer-more-view ">
                    <div className="bg-more flex justify-center">
                        <div className="heart-width flex align-centeer flex-direction">
                            <div className="more-view-title">更多商家</div>
                            <div className="more-view-desc">助力10000+商家降本增效</div>
                            <div className="brand-view-wraper">
                                {
                                    brands.map((item, index) => {
                                        return (
                                            <img src={item.pic} className="brand-img" key={index}
                                            ></img>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <BottomAppBar></BottomAppBar>
            </div >
        )
    }
}

export default CustomerWidget;