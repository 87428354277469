import React from "react";
import CallBarPhone from 'assets/h5/call_bar_phone.svg';
import CallBarCall from 'assets/h5/call_bar_call.svg';
import './bottomCallBar.scss';
import { Button } from "antd";
import routerUtils from "js/routerUtils";
import constants from "js/constants";

class BottomCallBar extends React.Component {
    constructor() {
        super();
        const bussiness = constants.PHONE_BUSINESS;
        const service = constants.PHONE_NUMBER;
        this.state = {
            bussiness,
            service
        }
    }

    toDownload() {
        routerUtils.routeTo('/app');
    }

    callBussiness() {
        window.location.href = "tel:" + this.state.bussiness;
    }

    callPhone() {
        window.location.href = "tel:" + this.state.service;
    }


    render() {
        return (
            <div className="call-bar-wraper">
                <div className="fit-view"></div>
                <div className="call-bar-mob-wraper">
                    <div className="call-bar-mob flex-align-center">
                        <div className="flex-in-center flex-direction bc-margin-right" onClick={this.callBussiness.bind(this)}>
                            <img src={CallBarPhone}></img>
                            <span className="text-sm bold text-deep-gray" style={{ marginTop: 2 }}>商务合作</span>
                        </div>
                        <div className="flex-in-center flex-direction" onClick={this.callPhone.bind(this)}>
                            <img src={CallBarCall}></img>
                            <span className="text-sm bold text-deep-gray" style={{ marginTop: 2 }}>客服电话</span>
                        </div>
                        <Button onClick={this.toDownload.bind(this)} type="primary" className="flex-sub" style={{ height: '40px', marginLeft: 24, borderRadius: 666 }}>免费注册</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default BottomCallBar