import './App.css';
import './styles/common.scss';

import AboutWidget from './pages/about/about';
import HomeWidget from './pages/home/home';
import CustomerWidget from 'pages/customer/customer';
import DeveloperWidget from 'pages/developer/developer';
import ProductWidget from 'pages/product/product';
import HomeWidgetMobile from 'h5/home/index';
import AboutMobileWight from 'h5/about';
import ProductMobileWight from 'h5/product';
import DeveloperMobileWight from 'h5/developer';
import CustomerMobileWight from 'h5/customer';
import DownloadWight from 'h5/download';


import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { useEffect } from 'react';
import routerUtils from 'js/routerUtils';
import { ConfigProvider } from 'antd';

function App() {
  //hashmode code
  useEffect(() => {
    if (routerUtils.HASH_MODE) {
      const onChange = () => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
      window.addEventListener('hashchange', onChange);
      return () => {
        window.removeEventListener('hashchange', onChange);
      }
    }
  })

  const titleHome = '大有单 | 大有新零售，一站式聚合订单管理平台';
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#16B0B0',
      },
    }}>
      <div className="App" style={{ color: 'black', fontSize: '14px' }} >
        <div className='main-content flex flex-direction'>
          <HashRouter>
            <Routes>
              <Route path='/' element={<HomeWidget title={titleHome} />} />
              <Route path='*' element={<HomeWidget title={titleHome} />} />
              <Route path='/home' element={<HomeWidget title={titleHome} />} />
              <Route path='/customer' element={<CustomerWidget title="大有单｜客户案例" />} />
              <Route path='/product' element={<ProductWidget title="大有单｜产品功能" />} />
              <Route path='/developer' element={<DeveloperWidget title="大有单｜开放平台" />} />
              <Route path='/about' element={<AboutWidget title="大有单｜关于我们" />} />
              <Route path='/app' element={<DownloadWight title="大有单｜下载" />} />
              <Route path='/h5/index' element={<HomeWidgetMobile title={titleHome} />} />
              <Route path='/h5/product' element={<ProductMobileWight title="大有单｜产品功能" />} />
              <Route path='/h5/developer' element={<DeveloperMobileWight title="大有单｜开放平台" />} />
              <Route path='/h5/customer' element={<CustomerMobileWight title="大有单｜客户案例" />} />
              <Route path='/h5/about' element={<AboutMobileWight title="大有单｜关于我们" />} />
            </Routes>
          </HashRouter>
          {/* <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomeWidget title={titleHome} key={'home1'} />} />
            <Route path='*' element={<HomeWidget title={titleHome} key={'home2'} />} />
            <Route path='/h5/index' element={<HomeWidgetMobile title={titleHome} key={'homem'} />} />
            <Route path='/home' element={<HomeWidget title={titleHome} key={'home3'} />} />
            <Route path='/customer' element={<CustomerWidget title="大有单｜客户案例" />} />
            <Route path='/product' element={<ProductWidget title="大有单｜产品功能" />} />
            <Route path='/developer' element={<DeveloperWidget title="大有单｜开放平台" />} />
            <Route path='/about' element={<AboutWidget title="大有单｜关于我们" />} />
          </Routes>
        </BrowserRouter> */}
        </div>
      </div>
    </ConfigProvider>
  );
}
export default App;
