import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import constants from 'js/constants';
import routeUtils from 'js/routerUtils';
import TopbarWight from 'h5/compontnts/topbar/topbar';
import BannerTextCustomer from 'assets/h5/banner_text_customer.svg';
import { Button } from 'antd';
import btnEnterSvg from 'assets/h5/btn_enter.svg';

import BottomCallBar from 'h5/compontnts/bottomCallBar/bottomCallBar';
import CustomerMobWidget from 'h5/compontnts/commonCustomer';
import BottomBarMobWidget from 'h5/compontnts/bottombar/bottombar';

class CustomerMobileWight extends BaseMobileComponents {
    constructor() {
        super();
        const brands = [];
        for (let i = 1; i < 13; i++) {
            brands.push({
                pic: require('assets/customer_pics/c_p_' + i + '.png')
            });
        }

        this.state = {
            brands
        }
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
    }

    toDownload() {
        routeUtils.routeTo('/app');
    }

    renderView() {
        const { brands } = this.state;
        return (
            <div className="root-view-mob-customer bg-white">
                <div className="banner-view">
                    <TopbarWight></TopbarWight>
                    <img src={BannerTextCustomer} className='banner-text'></img>
                    <div className='sub-text'>每一次服务，都在为业绩增长创新助力，在向美好生活前行</div>
                    <div>
                        <Button type='primary' style={{ marginTop: 8 }}>
                            <div className='flex-align-center' onClick={this.toDownload.bind(this)}>免费注册
                                <img src={btnEnterSvg} className='margin-left-xs'></img></div>
                        </Button>
                    </div>
                </div>
                <div className='second-part'>
                    <div className='common-inner-text-1 inner-text-1'>客户案例</div>
                    <div className='common-inner-text-2 margin-lr'>突破传统低效配送流程，步入智能配送新时代</div>
                    <div style={{ marginTop: 24 }}>
                        <CustomerMobWidget></CustomerMobWidget>
                    </div>
                </div>
                <div className='third-part'>
                    <div className='common-inner-text-1 inner-text-1'>更多商家</div>
                    <div className='common-inner-text-2'>助力10000+商家降本增效</div>
                    <div className='brands-wraper'>
                        <div className='cu-list grid col-3'>
                            {
                                brands.map((item, index) => {
                                    return (
                                        <div className='inner-item' key={index}>
                                            <img src={item.pic} className='inner-image'></img>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <BottomBarMobWidget></BottomBarMobWidget>
                <BottomCallBar></BottomCallBar>
            </div>
        )
    }
}

export default CustomerMobileWight;