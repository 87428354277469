import React from "react";
import { CountUp } from "countup.js";

class QuickNumber extends React.Component {
    constructor() {
        super()
    }

    componentDidMount() {
        const countUp = new CountUp('target' + this.props.index, this.props.maxNum, {
            duration: 4
        });
        countUp.start();
    }

    render() {
        return (
            <label id={'target' + this.props.index}>0</label>
        )
    }
}

export default QuickNumber;