import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import routeUtils from 'js/routerUtils';
import TopbarWight from 'h5/compontnts/topbar/topbar';
import BannerTextProduct from 'assets/h5/banner_text_product.svg';
import { Button } from 'antd';
import btnEnterSvg from 'assets/h5/btn_enter.svg';

import BottomCallBar from 'h5/compontnts/bottomCallBar/bottomCallBar';
import { Swiper } from 'antd-mobile';
import BottomBarMobWidget from 'h5/compontnts/bottombar/bottombar';

class ProductMobileWight extends BaseMobileComponents {
    constructor() {
        super();

        const logo_alpha_yellow = require('assets/more_icons/logo_alpha_yellow.png');
        const logo_alpha_blue = require('assets/more_icons/logo_alpha_blue.png');
        const logo_alpha_cyan = require('assets/more_icons/logo_alpha_cyan.png');
        const logo_alpha_gold = require('assets/more_icons/logo_alpha_gold.png');
        const logo_alpha_green = require('assets/more_icons/logo_alpha_green.png');
        const logo_alpha_orange = require('assets/more_icons/logo_alpha_orange.png');
        const logo_alpha_purple = require('assets/more_icons/logo_alpha_purple.png');
        const logo_alpha_more = require('assets/more_icons/logo_alpha_more.png');

        this.state = {
            skills: [{
                title: '订单同步',
                content: '同步订单至大有单，自动就近自动分单',
                icon: 'dy_order_desc1.png'
            }, {
                title: '配送回传',
                content: '支持状态订阅，配送状态变化实时回传',
                icon: 'dy_order_desc2.png'
            }, {
                title: '骑士位置',
                content: '实时获取骑士位置，回显到自有系统',
                icon: 'dy_order_desc3.png'
            }],
            stepList: [{
                iconTop: 'module_three_icon1.png',
                iconBottom: 'module_three_step1_order.png',
            }, {
                iconTop: 'module_three_icon2.png',
                iconBottom: 'module_three_step2.png',
            }, {
                iconTop: 'module_three_icon3.png',
                iconBottom: 'module_three_step3.png',
            }, {
                iconTop: 'module_three_icon4.png',
                iconBottom: 'module_three_step4.png',
            },],
            envs: [{
                img: require('assets/home_friend_env1.png'),
                title: '业务支撑',
                content: '针对行业定制特有方案',
            }, {
                img: require('assets/home_friend_env2.png'),
                title: '系统稳健',
                content: '日百万级的订单处理能力，保障核心流程便捷操作',
            }, {
                img: require('assets/home_friend_env3.png'),
                title: '服务保障',
                content: '提供1对1的服务对接链路',
            }, {
                img: require('assets/home_friend_env4.png'),
                title: '技术支持',
                content: '协助开发者拉通技术路径，全方位专业服务',
            }],
            more: [{
                title: '小票打印',
                desc: '适配众多小票打印机',
                icon: require('assets/more_icons/more_icon1.png'),
                border: 'rgba(255, 197, 7, 1)',
                logo: logo_alpha_yellow,
            }, {
                title: '免密支付',
                desc: '自动扣费，无需手动支付',
                icon: require('assets/more_icons/more_icon2.png'),
                border: 'rgba(40, 118, 255, 1)',
                logo: logo_alpha_blue,
            }, {
                title: '自动小费',
                desc: '鼓励骑士接单',
                icon: require('assets/more_icons/more_icon3.png'),
                border: 'rgba(22, 176, 176, 1)',
                logo: logo_alpha_cyan,
            }, {
                title: '自动分单',
                desc: '连锁多门店，就近分单',
                icon: require('assets/more_icons/more_icon4.png'),
                border: 'rgba(83, 89, 244, 1)',
                logo: logo_alpha_purple,
            }, {
                title: '自动接单',
                desc: '自动向平台确认订单',
                icon: require('assets/more_icons/more_icon5.png'),
                logo: logo_alpha_orange,
                border: 'rgba(255, 127, 31, 1)',
            }, {
                title: '数据统计',
                desc: '营收等多维数据统计分析',
                icon: require('assets/more_icons/more_icon6.png'),
                logo: logo_alpha_blue,
                border: 'rgba(40, 118, 255, 1)',
            }, {
                title: '多级账号',
                desc: '多级账号满足管理需求',
                icon: require('assets/more_icons/more_icon7.png'),
                border: 'rgba(22, 176, 176, 1)',
                logo: logo_alpha_cyan,
            }, {
                title: '运力偏好',
                desc: '选择优质的运力配送商',
                icon: require('assets/more_icons/more_icon8.png'),
                border: 'rgba(83, 89, 244, 1)',
                logo: logo_alpha_purple,

            }, {
                title: '地址簿',
                desc: '地址管理，发单更便捷',
                icon: require('assets/more_icons/more_icon9.png'),
                border: 'rgba(0, 190, 0, 1)',
                logo: logo_alpha_green,

            }, {
                title: '默认备注',
                desc: '向骑士备注默认信息',
                icon: require('assets/more_icons/more_icon10.png'),
                logo: logo_alpha_gold,
                border: 'rgba(255, 190, 6, 1)',
            }, {
                title: '保价、专送',
                desc: '贵重物品一对一专送',
                icon: require('assets/more_icons/more_icon11.png'),
                logo: logo_alpha_cyan,
                border: 'rgba(22, 176, 176, 1)',
            }, {
                title: '自有配送充值',
                desc: '充值多个配送运力',
                icon: require('assets/more_icons/more_icon12.png'),
                logo: logo_alpha_orange,
                border: 'rgba(255, 127, 31, 1)',
            }, {
                title: '创建订单',
                desc: '解决商家自有配送需求',
                icon: require('assets/more_icons/more_icon13.png'),
                logo: logo_alpha_purple,
                border: 'rgba(83, 89, 244, 1)',
            }, {
                title: '取回商品',
                desc: '一键从顾客方取回商品',
                icon: require('assets/more_icons/more_icon14.png'),
                logo: logo_alpha_cyan,
                border: 'rgba(22, 176, 176, 1)',
            }, {
                title: '更多功能',
                desc: '功能丰富、等你探索',
                icon: require('assets/more_icons/more_icon15.png'),
                logo: logo_alpha_more,
                border: 'rgba(140, 140, 140, 1)',
            }]
        };
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
    }

    toDownload() {
        routeUtils.routeTo('/app');
    }

    genarateBgColor(color) {
        return color.replace('1)', '0.08)');
    }

    onMouseEnter(item, index) {
        document.body.style.setProperty('--hover-color', item.border);
    }


    renderView() {
        const { more } = this.state;
        return (
            <div className="root-view-mob-product bg-white">
                <div className="banner-view">
                    <TopbarWight></TopbarWight>
                    <img src={BannerTextProduct} className='banner-text'></img>
                    <div className='sub-text'>一站式解决订单管理、物流配送、渠道运营，搭建同城订单全链路闭环</div>
                    <div>
                        <Button type='primary' style={{ marginTop: 8 }}>
                            <div className='flex-align-center' onClick={this.toDownload.bind(this)}>免费注册
                                <img src={btnEnterSvg} className='margin-left-xs'></img></div>
                        </Button>
                    </div>
                </div>
                <div className='second-part'>
                    <div className='common-inner-text-1 inner-text-1' style={{ marginTop: 48 }}>助力商户降本增效</div>
                    <div className='common-inner-text-2 margin-lr'>订单管理难、运力不足、赔付困难等成为行业难题,商家面临增量不增收的困境</div>
                    <div className="flex frame-view">
                        <img src={require('assets/product/priduct_frame_right.png')} className="frame-right-img" alt=""></img>
                        <div className="frame-view-left">
                            <div className="flex-align-center hit-title">
                                <img src={require('assets/product/unhappy.png')} className="face-img-top"></img>
                                <span className="margin-left-sm title-big">商户痛点</span>
                            </div>
                            <div className="flex-align-center hit-title block-margin-top">
                                <img src={require('assets/product/bad.png')} className="face-img"></img>
                                <span className="margin-left-sm">订单管理难</span>
                            </div>
                            <div className="block-desc">商家如果入驻多个外卖平台，需切换不同平台管理订单资源，营业额结算难，费时又易错。</div>
                            <div className="flex-align-center hit-title block-margin-top-big">
                                <img src={require('assets/product/bad.png')} className="face-img"></img>
                                <span className="margin-left-sm">运力不足</span>
                            </div>
                            <div className="block-desc">高峰期/极端天气情况，骑手资源有限，容易出现无人接单、延时派送的情况，消费者体验差，极易造成客户流失。</div>
                            <div className="flex-align-center hit-title block-margin-top">
                                <img src={require('assets/product/bad.png')} className="face-img"></img>
                                <span className="margin-left-sm">赔付困难</span>
                            </div>
                            <div className="block-desc">门店缺少专业人员与运力对接配送问题，餐损赔付问题难处理。</div>
                        </div>
                        <div className="frame-view-right">
                            <div className="flex-align-center hit-title">
                                <img src={require('assets/product/happy.png')} className="face-img-top"></img>
                                <span className="margin-left-sm title-big">大有单产品优势</span>
                            </div>
                            <div className="flex-align-center hit-title block-margin-top-big">
                                <img src={require('assets/product/product_icons_1.png')} className="face-img"></img>
                                <span className="margin-left-sm">一站式管理</span>
                            </div>
                            <div className="block-desc">支持连锁品牌开通多门店子账号，可共用钱包余额，提供智能账单，各门店收支一目了然。</div>
                            <div className="flex-align-center hit-title block-margin-top">
                                <img src={require('assets/product/product_icons_2.png')} className="face-img"></img>
                                <span className="margin-left-sm">聚合发单</span>
                            </div>
                            <div className="block-desc">对接多家配送平台，聚合发单，海量骑手在线接单。</div>
                            <div className="flex-align-center hit-title block-margin-top">
                                <img src={require('assets/product/product_icons_3.png')} className="face-img"></img>
                                <span className="margin-left-sm">优质服务</span>
                            </div>
                            <div className="block-desc">专业客服7*16h在线处理餐损、售后问题，与各运力合作紧密，可及时与各运力沟通处理门店配送问题。</div>
                        </div>
                    </div>
                </div>
                <div className='third-part'>
                    <div className='common-inner-text-1'>核心功能</div>
                    <div className='common-inner-text-2'>SAAS平台，赋能本地生活服务商家</div>
                    <Swiper stuckAtBoundary={false} slideSize={65} defaultIndex={0} style={{
                        '--track-padding': ' 0 0 16px',
                        '--active-dot-color': 'white',
                        '--dot-spacing': '8px',
                    }}>
                        <Swiper.Item>
                            <img src={require('assets/h5/product_banner_1.png')} style={{ width: (255 * 1.2), height: (366 * 1.2) }}></img>
                        </Swiper.Item>
                        <Swiper.Item>
                            <img src={require('assets/h5/product_banner_2.png')} style={{ width: (255 * 1.2), height: (366 * 1.2) }}></img>
                        </Swiper.Item>
                    </Swiper>
                </div>
                <div className='fourth-part'>
                    <div className="flex justify-center text-left inner-block-three">
                        <div className="flex justify-center flex-direction">
                            <div className='common-inner-text-1 inner-text-1' style={{ marginTop: 40 }}>更多功能</div>
                            <div className='common-inner-text-2'>百余项功能免费开放任你探索</div>
                            <div className="flex-on-center" style={{ padding: '0 12px' }}>
                                <div className="inner-more-square-wraper cu-list grid col-3">
                                    {
                                        more.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="more-square hand" style={{ background: this.genarateBgColor(item.border) }}
                                                        onMouseEnter={this.onMouseEnter.bind(this, item, index)} key={index}>
                                                        <img className="more-square-img" src={item.icon} />
                                                        <div className="more-square-title text-left">{item.title}</div>
                                                        <div className="more-square-desc text-left one-line-text">{item.desc}</div>
                                                        {/* <img src={item.logo} className="logo-alpha" /> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomBarMobWidget></BottomBarMobWidget>
                <BottomCallBar></BottomCallBar>
            </div>
        )
    }
}

export default ProductMobileWight;