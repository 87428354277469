import constants from "./constants";
// import { createHashHistory } from "history";
var HASH_MODE = true;

function routeTo(path) {
    if (HASH_MODE) {
        if (window.location.hash == ('#' + path)) {
            window.location.reload()
        } else {
            if (path.startsWith('http://') || path.startsWith('https://')) {
                window.location.href = path;
            } else {
                // createHashHistory().push(path);
                window.location.hash = path;
                // document.documentElement.scrollTop = document.body.scrollTop = 0;
                // window.location.reload()
            }
        }
    } else {
        window.location.href = path;
    }
}

function openPage(url, isOpen = true) {
    if (isOpen) {
        window.open(url);
    }
}

function openPEISONG() {
    window.open(constants.PEISONG_OPEN);
}

export default {
    routeTo, openPage, openPEISONG, HASH_MODE
}