import BaseMobileComponents from 'common/BaseComponentsM';
import './index.scss';
import constants from 'js/constants';
import routeUtils from 'js/routerUtils';

class DownloadWight extends BaseMobileComponents {
    constructor() {
        super();
        this.state = {
            showDialog: false,
        }
    }

    // // 判断安卓
    // isAndroid() {
    //     var u = navigator.userAgent;
    //     if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //         if (window.ShowFitness !== undefined) return true;
    //     }
    //     return false;
    // }

    // 判断设备为 ios
    isIos() {
        var u = navigator.userAgent;
        if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
            return true;
        }
        return false;
    }

    onDownloadClick() {
        if (this.isIos()) {
            routeUtils.routeTo(constants.IOS);
            if (this.isInWechat()) {
                this.setState({
                    showDialog: true,
                })
            }
            // routeUtils.routeTo(constants.ANDROID);
        } else {
            routeUtils.routeTo(constants.ANDROID);
        }
    }

    isInWechat() {
        return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
    }

    hideDialog() {
        this.setState({
            showDialog: false,
        })
    }

    renderView() {
        const { showDialog } = this.state;
        return (
            <div className="root-view-download">
                <div className='logo-wraper'>
                    <img src={require('assets/mobile/mobile_logo.png')} className="m-logo"></img>
                </div>
                <img src={require('assets/mobile/mobile_logo_name.png')} className="m-title"></img>
                <div className="m-title-desc">欢迎下载使用大有单APP</div>
                <div className="flex" style={{ width: '100%', paddingBottom: '15vh' }}>
                    <div className="main-btn" onClick={this.onDownloadClick.bind(this)}>下载APP</div>
                </div>
                {
                    showDialog ? (<div className='ios-dialog' onClick={this.hideDialog.bind(this)}>
                        <div className='dialog-content'>
                            <div className='content-text'>请点击右上角按钮，然后再弹出的菜单中，点击<span>"在浏览器中打开"</span>，即可安装</div>
                            <img src={require('assets/rt_arrow.png')} className='rt-arrow' />
                        </div>
                    </div>) : ''
                }
            </div>
        )
    }
}

export default DownloadWight;