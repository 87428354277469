import './bottombar.scss';
import React from 'react';
import app_android from 'assets/svg/app_android.svg';
import app_android_on from 'assets/svg/app_android_on.svg';
import app_ios from 'assets/svg/app_ios.svg';
import app_ios_on from 'assets/svg/app_ios_on.svg';
import constants from 'js/constants';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import routerUtils from 'js/routerUtils';
import { ImageViewer } from 'antd-mobile';

class BottomBarMobWidget extends React.Component {
    constructor() {
        super();
        const phoneNumber = constants.PHONE_NUMBER;
        this.state = {
            phoneNumber,
            visibleDayoudanService: false,
            visibleDayoudan: false,
            applist: [{
                title: 'Android',
                url: constants.ANDROID,
                svg: app_android,
                svg_on: app_android_on,
            }, {
                title: 'iOS',
                url: constants.IOS,
                svg: app_ios,
                svg_on: app_ios_on,
            }],
            currentAppIndex: -1,
            openIndex: -1,
            navi: [{
                title: '产品',
                children: [{
                    title: '大有单',
                    path: '/h5/index'
                }, {
                    title: '订单开放平台',
                    path: '/h5/developer'
                }, {
                    title: '配送开放平台',
                    path: '/h5/developer'
                }]
            }, {
                title: '适用行业',
                children: [{
                    title: '烧烤夜宵',
                    path: '/h5/customer'
                }, {
                    title: '餐饮小吃',
                    path: '/h5/customer'
                }, {
                    title: '蛋糕甜品',
                    path: '/h5/customer'
                }, {
                    title: '浪漫鲜花',
                    path: '/h5/customer'
                }, {
                    title: '生鲜果蔬',
                    path: '/h5/customer'
                }, {
                    title: '零售商超',
                    path: '/h5/customer'
                }]
            }, {
                title: '关于我们',
                children: [{
                    title: '公司介绍',
                    path: '/h5/about'
                }, {
                    title: '加入迪嘉',
                    path: '/h5/about'
                }]
            }]
        }
    }

    toDownload() {
        routerUtils.routeTo('/app');
    }

    mouseInApos(item, index) {
    }

    mouseOutApos(item, index) {
        this.setState({
            currentAppIndex: -1
        })
    }

    onAppClick(item, index) {
        this.toDownload();
    }

    openMenu(item, index) {
        const target = this.state.openIndex == index ? -1 : index;
        this.setState({
            openIndex: target
        });
    }

    onMenuClick(item, index) {
        console.log(item, '????');
        routerUtils.routeTo(item.path);
    }

    showImage(visibleDayoudan) {
        this.setState({
            visibleDayoudan,
        })
    }

    showImageService(visibleDayoudanService) {
        this.setState({
            visibleDayoudanService,
        })
    }


    render() {
        const { applist, currentAppIndex, openIndex, navi, visibleDayoudan, visibleDayoudanService, phoneNumber } = this.state;
        return (
            <div className='mob-bottom-bar'>
                <div className='inner-app-bar'>
                    <img src={require('assets/h5/phone_cut.png')} className='phone-cut-img'></img>
                    <div className='inner-right-view'>
                        <div className='bar-name-text text-left'>即刻体验大有单</div>
                        <div className='flex margin-top'>
                            <div className='inner-app-download'>
                                {
                                    applist.map((item, index) => {
                                        return (
                                            <div key={index} className='icon-img-frame flex-on-center flex-direction hand' onMouseEnter={this.mouseInApos.bind(this, item, index)}
                                                onMouseLeave={this.mouseOutApos.bind(this, item, index)} onClick={this.onAppClick.bind(this, item, index)} style={{ position: 'relative', zIndex: 100 }}>
                                                <img src={index == currentAppIndex ? item.svg_on : item.svg} className='icon-img' />
                                                <div className={`${index == currentAppIndex ? 'main-color' : 'text-black'}`}>{item.title}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='inner-bar'></div>
                </div>

                <div className='mob-bottom-menu-view text-left'>
                    {
                        navi.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className='parent-navi-menu flex-align-center justify-between' onClick={this.openMenu.bind(this, item, index)}>
                                        {item.title}
                                        {
                                            index == openIndex ?
                                                (<RightOutlined style={{ fontSize: '11px' }}></RightOutlined>)
                                                :
                                                (<DownOutlined size={8} style={{ fontSize: '11px' }}></DownOutlined>)
                                        }
                                    </div>
                                    <div className='child-navi-menu-wraper' style={{ maxHeight: index == openIndex ? '600px' : '0px', overflow: 'hidden' }} >
                                        {item.children.map((child, childIndex) => {
                                            return (
                                                <div key={childIndex} className='child-navi-menu' onClick={this.onMenuClick.bind(this, child, childIndex)}>
                                                    {child.title}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div>
                        <div className='parent-navi-menu flex-align-center justify-between' onClick={this.openMenu.bind(this, {}, 4)}>
                            联系我们
                            {
                                openIndex == 4 ?
                                    (<RightOutlined style={{ fontSize: '11px' }}></RightOutlined>)
                                    :
                                    (<DownOutlined size={8} style={{ fontSize: '11px' }}></DownOutlined>)
                            }
                        </div>
                        <div className='child-navi-menu-wraper contact-us-view text-deep-gray' style={{ maxHeight: openIndex == 4 ? '600px' : '0px', overflow: 'hidden' }} >
                            <div className='padding-top-sm'>公司名称：湖南迪嘉科技有限公司</div>
                            <div className='padding-top-sm'>公司电话：{phoneNumber}</div>
                            <div className='padding-top-sm'>公司地址：湖南省长沙市岳麓区世贸希尔顿大楼12楼</div>
                        </div>
                    </div>

                </div>

                <div className='inner-code-view'>
                    <div className='flex with-bottom-line'>
                        <div className='code-wechat flex-on-center flex-direction' onClick={this.showImage.bind(this, true)}>
                            <img src={require('assets/wechat_service.png')} className='inner-img'></img>
                            <span className='inner-text'>扫码关注大有单</span>
                            <ImageViewer
                                classNames={{
                                    mask: 'customize-mask',
                                    body: 'customize-body',
                                }}
                                image={require('assets/wechat_service.png')}
                                visible={visibleDayoudan}
                                onClose={() => {
                                    this.showImage(false)
                                }}
                            />
                        </div>
                        <div className='code-wechat flex-on-center flex-direction' style={{ marginLeft: 22 }} onClick={this.showImageService.bind(this, true)}>
                            <img src={require('assets/wechat_customer.png')} className='inner-img'></img>
                            <span className='inner-text'>扫码联系客服专员</span>
                            <ImageViewer
                                classNames={{
                                    mask: 'customize-mask',
                                    body: 'customize-body',
                                }}
                                image={require('assets/wechat_customer.png')}
                                visible={visibleDayoudanService}
                                onClose={() => {
                                    this.showImageService(false)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="inner-copy-right">
                    <div className="flex-on-center">
                        <img src={require('assets/beian.png')} className="bei-an-img margin-right-sm"></img>
                        <a className="common-hover" style={{ marginRight: 5 }} href="https://beian.miit.gov.cn/" target="_blank"> 湘ICP备2022000854号-2</a>
                        <span style={{ fontSize: 10, margin: '0 8px 0 4px' }}>|</span>
                        <a className="common-hover" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43011102002202">湘公网安备 43011102002202号</a>
                    </div>
                    <div className='text-copy'>
                        © 2021~2024 迪嘉科技
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBarMobWidget;