import './bottomAppBar.scss';
import React from 'react';
import app_android from 'assets/svg/app_android.svg';
import app_android_on from 'assets/svg/app_android_on.svg';
import app_ios from 'assets/svg/app_ios.svg';
import app_ios_on from 'assets/svg/app_ios_on.svg';
import app_web from 'assets/svg/app_web.svg';
import app_web_on from 'assets/svg/app_web_on.svg';
import app_win from 'assets/svg/app_win.svg';
import app_win_on from 'assets/svg/app_win_on.svg';
import { Popover } from 'antd';
import routerUtils from 'js/routerUtils';
import constants from 'js/constants';
import BottomBar from 'common/bottombar/bottombar';

class BottomAppBar extends React.Component {
    constructor() {
        super();
        this.state = {
            applist: [{
                title: 'Android',
                url: constants.ANDROID,
                svg: app_android,
                svg_on: app_android_on,
                pic: require('assets/app_yingyongbao.png')
            }, {
                title: 'iOS',
                url: constants.IOS,
                svg: app_ios,
                svg_on: app_ios_on,
                pic: require('assets/app_ios.png')

            },
            //  {
            //     title: 'Windows',
            //     url: '',
            //     svg: app_win,
            //     svg_on: app_win_on
            // }, 
            {
                title: 'WEB',
                url: constants.DAYOUDAN,
                svg: app_web,
                svg_on: app_web_on
            }],
            currentPhonePos: 1,
            currentAppIndex: -1,
        }
    }

    mouseEnterLister(pos) {
        this.setState({
            currentPhonePos: pos
        })
    }

    mouseInApos(item, index) {
        this.setState({
            currentAppIndex: index
        })
    }

    mouseOutApos(item, index) {
        this.setState({
            currentAppIndex: -1
        })
    }

    onAppClick(item, index) {
        routerUtils.openPage(item.url);
    }

    render() {
        const { applist, currentPhonePos, currentAppIndex } = this.state;
        return (
            <div>
                <div className="module-width min-heart-width flex justify-center" style={{ position: 'relative' }}>
                    <div className='app-bar-bg'></div>
                    <div className="heart-width flex home-page-phone-bottom">
                        <div style={{ width: 643 }}>
                        </div>
                        <div>
                            <div className="phone-img-left-wraper" style={{ top: currentPhonePos == 0 ? 38 : 108, zIndex: currentPhonePos ? 2 : 3 }}
                                onMouseEnter={this.mouseEnterLister.bind(this, 0)}>
                                <img src={require('assets/phone_left.png')} className="phone-img-left" ></img>
                            </div>
                            <div className="phone-img-right-wraper" style={{ top: currentPhonePos == 1 ? 38 : 108, zIndex: currentPhonePos ? 3 : 2 }}
                                onMouseEnter={this.mouseEnterLister.bind(this, 1)}>
                                <img src={require('assets/phone_right.png')} className="phone-img-right"></img>
                            </div>
                        </div>

                        <div className="hb-right-view">
                            <div className="hb-title text-left">即刻体验大有单</div>
                            <div className="flex" style={{ marginBottom: 24 }}>
                                {
                                    applist.map(
                                        (item, index) => {
                                            return (
                                                <div key={index}>
                                                    {
                                                        item.pic ?
                                                            (
                                                                <Popover style={{ padding: 0 }} overlayClassName='qr-code-popover' marginXS={0} content={(<img className='qr-code-pop' src={item.pic} />)}>
                                                                    <div className='icon-img-frame flex-on-center flex-direction hand' onMouseEnter={this.mouseInApos.bind(this, item, index)}
                                                                        onMouseLeave={this.mouseOutApos.bind(this, item, index)} onClick={this.onAppClick.bind(this, item, index)}>
                                                                        <img src={index == currentAppIndex ? item.svg_on : item.svg} className='icon-img' />
                                                                        <div className={`app-name ${index == currentAppIndex ? 'main-color' : 'text-black'}`}>{item.title}</div>
                                                                    </div>
                                                                </Popover>
                                                            ) :
                                                            (
                                                                <div className='icon-img-frame flex-on-center flex-direction hand' onMouseEnter={this.mouseInApos.bind(this, item, index)}
                                                                    onMouseLeave={this.mouseOutApos.bind(this, item, index)} onClick={this.onAppClick.bind(this, item, index)}>
                                                                    <img src={index == currentAppIndex ? item.svg_on : item.svg} className='icon-img' />
                                                                    <div className={`app-name ${index == currentAppIndex ? 'main-color' : 'text-black'}`}>{item.title}</div>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <BottomBar></BottomBar>
            </div>
        )
    }
}

export default BottomAppBar;