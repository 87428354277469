import React from "react";
import './horizontalScroller.scss';

class HorizontalScroller extends React.Component {
    constructor() {
        super();
        this.state = {
            pics1: [],
            pics2: [],
        }
    }

    componentDidMount() {
        let pics1 = [];
        for (let i = 0; i < 8; i++) {
            pics1.push(require('../../../../assets/partner/partner_' + (i + 1) + '.png'))
        }
        pics1 = pics1.concat(pics1, pics1);

        let pics2 = [];
        for (let i = 8; i < 16; i++) {
            pics2.push(require('../../../../assets/partner/partner_' + (i + 1) + '.png'))
        }
        pics2 = pics2.concat(pics2, pics2);

        this.setState({
            pics1, pics2
        })
    }

    render() {
        const { pics1, pics2 } = this.state;
        return (
            <div >
                <div className="module-width min-heart-width flex justify-center partners-view">
                    <div className="heart-width flex justify-center flex-direction">
                        <div className="p-big-title">合作平台</div>
                        <div className="p-sub-title">解决订单处理、物流配送、渠道运营，搭建订单全链路闭环</div>
                    </div>
                </div>
                <div className="h-section-part2-plots-top horizontal-scroller" style={{ marginTop: 24 }}>
                    <div className="pic1-wraper">
                        {pics1.map((item, index) => {
                            return (
                                <div className="item-partner flex-on-center" key={index}>
                                    <img src={item}></img>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="h-section-part2-plots-bottom">
                    <div className="pic1-wraper">
                        {pics2.map((item, index) => {
                            return (
                                <div className="item-partner flex-on-center" key={index}>
                                    <img src={item}></img>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default HorizontalScroller;